import Navbar from "Components/Common/Navbar";
import React from "react";
import Banner from "./Banner";
import HowItStarted from "./HowItStarted";
import PlanVision from "./PlanVision";
import WhyChooseUs from "./WhyChooseUs";
import GetAQuote from "./GetAQuote";
import Footer from "Components/landing/Footer";

const About = () => {
  return (
    <>
      <Navbar />
      <Banner />
      <HowItStarted />
      <PlanVision />
      <WhyChooseUs />
      <GetAQuote />
      <Footer />
    </>
  );
};

export default About;
