import React from "react";
import TermLifeImage from "../../assets/termLife.png";
import { useNavigate } from "react-router-dom";
export interface BannerDataInterface {
  name: String;
  link: String;
}
const Banner: React.FC<BannerDataInterface> = (data: BannerDataInterface) => {
  const navigate:any = useNavigate();
  return (
    <div className="Banner">
      <div className="Banner_Image">
        <img
          src={TermLifeImage}
          alt="Term_Life_Image"
          className="Banner_Image_Img"
        />
      </div>
      <div className="Banner_Content">
        <h1>{data.name}</h1>
        <div className="Banner_Content_Button">
          <button
            className="button-primary"
            onClick={() => {
              navigate(data.link);
            }}
          >
            GET A QUOTE
          </button>
        </div>
      </div>
    </div>
  );
};

export default Banner;
