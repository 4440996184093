import React, { useState } from "react";
import Navbar from "Components/Common/Navbar";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import ChoosePlan from "./Forms/ChoosePlan";
import ThirdParty from "./Forms/ThirdParty";
import PersonalDetails from "pages/Life/Forms/PersonalDetails";
import Comprehensive from "./Forms/Comprehensive";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import { postTwoWheelerQuote } from "slices/policy/thunk";

const TwoWheelerForm = () => {
  const [activeTab, setActiveTab] = useState(1);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      planType: "",
      category: "",
      capacity: "",
      numberOfPassenger: "1",
      manufacturedYear: "",
      vechileCost: "",
      riotStrikeDamage: false,
      sumAssured: 0,
      term: 0,
      dateOfBirth: [],
      name: "",
      phoneNumber: "",
      email: "",
      policyType: "Endowment",
    },
    validationSchema: Yup.object({
      planType: Yup.string().required("Please Choose a Plan"),
      category: Yup.string().required("Please Select a Category"),
      capacity: Yup.string().required("Please Select capacity"),
      numberOfPassenger: Yup.string(),
      manufacturedYear: Yup.string(),
      vehicleCost: Yup.string(),
      riotStrikeDamage: Yup.boolean(),
      name: Yup.string().required("Please enter your name"),
      phoneNumber: Yup.string().required("Phone number is required"),
      email: Yup.string().required("Email is required"),
    }),
    onSubmit: (values) => {
      dispatch(postTwoWheelerQuote(values));
      localStorage.setItem("twoWheeler", JSON.stringify(values));
      navigate("/two-wheeler-policy");
    },
  });

  return (
    <React.Fragment>
      {/* <Navbar /> */}
      <div className="Layout">
        {activeTab === 1 && (
          <ChoosePlan
            validation={validation}
            setTab={(item: number) => setActiveTab(item)}
          />
        )}
        {activeTab === 2 && validation.values.planType === "ThirdParty" && (
          <ThirdParty
            validation={validation}
            setTab={(item: number) => setActiveTab(item)}
          />
        )}
        {activeTab === 2 && validation.values.planType === "Comprehensive" && (
          <Comprehensive
            validation={validation}
            setTab={(item: number) => setActiveTab(item)}
          />
        )}
        {activeTab === 3 && (
          <PersonalDetails
            validation={validation}
            setTab={(item: number) => setActiveTab(item)}
            steps={3}
            type="Create a two wheeler plan"
          />
        )}
        {/* {activeTab === 1 && (
          <PolicyDetails
            validation={validation}
            setTab={(item: number) => setActiveTab(item)}
          />
        )}
        {activeTab === 2 && (
          <PersonalDetails
            validation={validation}
            setTab={(item: number) => setActiveTab(item)}
          />
        )} */}
      </div>
    </React.Fragment>
  );
};

export default TwoWheelerForm;
