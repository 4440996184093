import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

import PolicyReducer from "./policy/reducer";
import EndowmentReducer from "./endowmentPolicy/reducer";
import TwoWheelerReducer from "./twoWheeler/reducer";
import FourWheelerReducer from "./fourWheeler/reducer";
import TravelInsuranceReducer from "./travel/reducer";
import TermReducer from "./termLife/reducer";
import ChildrenReducer from "./childrenPlan/reducer";
import WholeLifeReducer from "./wholeLifePlan/reducer";
const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Policy: PolicyReducer,
  Endowment: EndowmentReducer,
  TermLife: TermReducer,
  TwoWheeler: TwoWheelerReducer,
  FourWheeler: FourWheelerReducer,
  Travel: TravelInsuranceReducer,
  ChildrenPlan: ChildrenReducer,
  WholeLife: WholeLifeReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
