import React from "react";
import parse from "html-react-parser";
const Rendering = ({ setSidebarVisible, sidebardata }: any) => {
  // const [header, setHeader] = React.useState<any>("");
  // const [benefits, setBenefits] = React.useState<any>("");
  // const [policyterm, setPolicyterm] = React.useState<any>("");
  // const [minmaxage, setMinmaxage] = React.useState<any>("");
  // const [features, setFeatures] = React.useState<any>("");
  // const fetchdata = async () => {
  //   try {
  //     const response = await fetch("http://localhost:8000/term-life-policy");
  //     const data = await response.json();
  //     
  //     const settingdata = data.data.filter(
  //       (item: any) => item.insuranceName === "Nepal Life iEnsure (single pay)"
  //     );
  //     const tobeparse = settingdata[0].description;
  //     const parsedlist = tobeparse.split("<p>$$$</p>");
  //     
  //     setHeader(parsedlist[0]);
  //     let modifiedString = parsedlist[1]
  //       .replace(
  //         /<li>/g,
  //         "<li><img src='https://utfs.io/f/75d78c82-1bd5-4ef5-b12c-1b88d8e7cf3a-1jf9c9.svg' alt='bullet'/><div>"
  //       )
  //       .replace(/<\/li>/g, "</div></li>");
  //     setBenefits(modifiedString);
  //     setPolicyterm(parsedlist[2]);
  //     setMinmaxage(parsedlist[3]);
  //     setFeatures(parsedlist[4]);

  //     
  //   } catch (error) {
  //     
  //   }
  // };
  // React.useEffect(() => {
  //   fetchdata();
  // }, []);

  
  const { company, insuranceName, companyLogo, description, minage, maxage } =
    sidebardata;
    if (!description){
      setSidebarVisible(false)
      return null
    }
  const parsedlist = description.split("<p>$$$</p>");
  const header = parsedlist[0];
  let modifiedString = parsedlist[1]
    .replace(
      /<li>/g,
      "<li><img src='https://utfs.io/f/75d78c82-1bd5-4ef5-b12c-1b88d8e7cf3a-1jf9c9.svg' alt='bullet'/><div>"
    )
    .replace(/<\/li>/g, "</div></li>");
  const benefits = modifiedString;
  const policyterm = parsedlist[2];

  const features = parsedlist[4];
  console.log(header)
  console.log(benefits)
  console.log(policyterm)
  console.log(features);

  return (
    <div className="plandetails"  >
      <div className="plandetails_header">
        <div className="insurancecompany">
          <img src={companyLogo} alt="Nepal Life Insurance" className="insurancecompany_Logo"/>
          <div className="companyinformation">
            <div className="header">{company}</div>
            <div className="insurancename">{insuranceName}</div>
            <div className="ADB">Must incorporate ADB- Re1 per thousand.</div>
          </div>
        </div>
        <div className="cross">
          <button onClick={(e) => setSidebarVisible(false)}></button>
        </div>
      </div>
      <div className="plandetails_information">
        {header && <div className="insuranceinfo">{parse(header)}</div>}
        {benefits && <div className="benefits">{parse(benefits)}</div>}
        {policyterm && <div className="policyterm">{parse(policyterm)}</div>}
        <div className="entrydate">
          <p>Minimum Entry Date: {minage} years old</p>
          <p>Maximum Entry Date: {maxage} years old</p>
        </div>
        {features && <div className="features">{parse(features)}</div>}
      </div>
    </div>
  );
};

export default Rendering;
