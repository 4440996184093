import React from "react";
import Navbar from "../../Components/Common/Navbar";
import Banner, { BannerDataInterface } from "../layouts/Banner";
import Definition, { CompareInterface } from "testPages/layouts/Definition";
import Offers, { OfferInterface } from "testPages/layouts/Offers";
import CostEffective from "../../assets/costEffective.svg";
import FinancialProtection from "../../assets/financialProtection.svg";
import Flexibility from "../../assets/flexibility.svg";
import Affordability from "../../assets/affordability.svg";
import LifeStage from "../../assets/lifeStage.svg";
import CoverageAmount from "../../assets/CoverageAmount.png";
import ProtectionDuration from "../../assets/ProtectionDuration.png";
import FinancialSecurity from "../../assets/FinancialSecurity.png";
import PeaceOfMind from "../../assets/PeaceOfMind.png";
import Works, { WorkInterface } from "testPages/layouts/Works";
import FAQs, { FAQItem } from "testPages/layouts/FAQs";
import Footer from "Components/landing/Footer";
import Contact from "Components/landing/Contact";
import TwoWheelerForm from "pages/TwoWheeler";
import { Helmet } from "react-helmet-async";
interface EndowmentDataInterface {
  banner: BannerDataInterface;
  compare: CompareInterface;
  offers: OfferInterface;
  works: WorkInterface;
  faqData: FAQItem[];
}
const EndowmentData: EndowmentDataInterface = {
  banner: {
    name: "Two Wheeler",
    link: "/two-wheeler-quote",
  },
  compare: {
    title: "How to compare Two Wheeler policy?",
    paragraph: [
      "1. Enter your age.",
      "2. Enter the term you want to be insured for.",
      "3. Enter the sum assured",
      "4. Compare between policies.",
      "5. View details.",
    ],
    name: "Two Wheeler",
    importance:
      "Bike Insurance/ two wheeler insurance  provides coverage for motorcycles, scooters, and other two-wheeled vehicles",
  },
  offers: {
    policyName: "Two Wheeler Insurance Policy",
    offerData: [
      {
        image: CostEffective,
        text: "Third Party Liability Coverage",
      },
      {
        image: FinancialProtection,
        text: "Own Damage Coverage",
      },
      {
        image: Affordability,
        text: "Comprehensive Coverage",
      },
      {
        image: Flexibility,
        text: "Optional Add-Ons",
      },
    ],
  },
  works: {
    policyName: "Two Wheeler Insurance Policy",
    insurance: "Two Wheeler Insurance",
    workData: [
      {
        id: "01",
        image: LifeStage,
        text: "Age 35, facing financial responsibilities like mortgage, children's education, etc.",
        heading: "Life Stage",
      },
      {
        id: "02",
        image: CoverageAmount,
        text: "NRS 500,000 policy ensures substantial support for beneficiaries.",
        heading: "Coverage Amount",
      },
      {
        id: "03",
        image: ProtectionDuration,
        text: "20-year term aligns with family's reliance on income.",
        heading: "Protection Duration",
      },
      {
        id: "04",
        image: FinancialSecurity,
        text: "Payout aids family's financial stability in case of death.",
        heading: "Financial Security",
      },
      {
        id: "05",
        image: PeaceOfMind,
        text: "Family's financial security brings peace, enabling joyful living.",
        heading: "Peace of Mind",
      },
    ],
  },
  faqData: [
    {
      id: 1,
      title: "Is two-wheeler insurance mandatory?",
      description: [
        "Yes,Third party insurance for two-wheeler insurance is mandatory in Nepal.",
        "It is a legal requirement to have at least a minimum level of insurance coverage to ride a motorcycle or scooter on public roads.",
      ],
    },
    {
      id: 2,
      title: "What does two-wheeler insurance cover?",
      description: [
        "Two-wheeler insurance typically provides coverage for the following:",
        "a) Own Damage (OD) cover: This covers damages to your own vehicle due to accidents, theft, fire, natural disasters, and other unforeseen events.",
        "b) Third-party liability cover: This covers damages or injuries caused to third parties (other people or property) in an accident involving your two-wheeler.",
      ],
    },
    {
      id: 3,
      title: " What are the types of two-wheeler insurance policies?",
      description: [
        "there are two main types of two-wheeler insurance policies:",
        "a) Third-party liability insurance: This provides coverage for damages or injuries caused to third parties in an accident involving your two-wheeler.",
        "b) Comprehensive insurance: This offers coverage for damages to your own vehicle as well as third-party liabilities. It includes protection against accidents, theft, natural disasters, and other unforeseen events.",
      ],
    },
    {
      id: 4,
      title: "How is the premium for two-wheeler insurance calculated?",
      description: [
        "The premium for two-wheeler insurance is determined based on various factors, including:",
        "a) Cubic capacity (CC) of the two-wheeler",
        "b) Insured declared value (IDV) - the current market value of the vehicle",
        "c) Policy coverage type (third-party or comprehensive)",
        "d) Policyholder's age, riding experience, and claims history",
        "e) Add-on coverage options, such as zero depreciation or personal accident cover",
      ],
    },
  ],
};
const TwoWheeler = () => {
  const { banner, compare, offers, works, faqData } =
    EndowmentData as EndowmentDataInterface;
  return (
    <>
      {" "}
      <Helmet>
        <title>
          Beemalaya | Compare and find best two wheeler policies in Nepal
        </title>
        <meta
          name="description"
          content="Find the best Two Wheeler insurance Policy from Nepal's top insurer that suits you before you buy it.
      "
        />
      </Helmet>
      <Navbar />
      <TwoWheelerForm />
      {/* <div className="Layout"> */}
      {/* <Banner {...banner} /> */}
      {/* <Definition {...compare} /> */}
      <Offers {...offers} />
      <Works {...works} />
      <FAQs data={faqData} />
      <Contact />
      <Footer />
      {/* </div> */}
    </>
  );
};

export default TwoWheeler;
