import React, { useEffect } from "react";

//import Scss
import "./scss/main.scss";

//imoprt Route
import Route from "./Routes";
// import { getBlogs } from "slices/thunks";
import { HelmetProvider } from "react-helmet-async";

function App() {
  // const dispatch = useDispatch<AppDispatch>()
  // useEffect(()=>{
  //   dispatch(getBlogs())
  // })
  const helmetContext = {};
  return (
    <React.Fragment>
      <HelmetProvider context={helmetContext}>
        <Route />
      </HelmetProvider>
    </React.Fragment>
  );
}

export default App;
