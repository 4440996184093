import React from 'react'

const ProductsCard = () => {
    return (
        <div className='productcard'>
            <img src="https://placehold.co/376x196" alt="" className="productcard_image" />
            <div className="productcard_content">
                <div className="productcard_fixedimage">
                    <img src="https://placehold.co/42x48" alt="" />
                </div>
                <div className="productcard_content_header">
                    Term life
                </div>
                <div className="productcard_content_desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.                </div>
                <button className="productcard_content_link">
                    Learn More -{">"}
                </button>
            </div>
        </div>
    )
}

export default ProductsCard
