import { createSlice } from "@reduxjs/toolkit";

import { getLifeInsuranceData } from "helpers/sessionStorageHelper";
import { getTwoWheelerPolicy } from "./thunk";

export interface TwoWheelerInsurance {
  company: string;
  planName: string;
  stampCost: number;
  vatPercent: number;
  prices: {
    minCC: number;
    maxCC: number;
    thirdPartyAdd?: number;
    bikeAgeBands: {
      minAge: number;
      maxAge: number;
      percent: number;
      costPercent: number;
    }[];
  }[];
  leastAmount: number;
}
export interface TwoWheelerInsuranceReducer {
  status: "success" | "pending" | "error";
  policies: TwoWheelerInsurance[];
  cc: number;
  planType: String;
  noOfPassenger: number;
  manufacturedYear: number;
  vechileCost: number;
  riotStrikeDamage: Boolean;
}

export const initialState: TwoWheelerInsuranceReducer = {
  status: "pending",
  policies: [],
  cc: 0,
  planType: "",
  noOfPassenger: 0,
  manufacturedYear: 0,
  vechileCost: 0,
  riotStrikeDamage: false,
  //   policyModified:[]
};

const twoWheelerSlice = createSlice({
  name: "twoWheelerPlan",
  initialState,
  reducers: {
    setTwoWheelerPolicyDetails: (state, action) => {
      state.cc = action.payload.cc;
      state.planType = action.payload.planType;
      state.noOfPassenger = action.payload.numberOfPassenger;
      state.manufacturedYear = action.payload.manufacturedYear;
      state.vechileCost = action.payload.vehicleCost;
      state.riotStrikeDamage = action.payload.riotStrikeDamage;
    },
    setTwoWheelerPolicyInitial: (state) => {
      state.cc = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTwoWheelerPolicy.fulfilled, (state, action) => {
      state.status = "success";
      
      
      state.policies = (action.payload as { data: TwoWheelerInsurance[] }).data;
    });
  },
});

export const { setTwoWheelerPolicyDetails, setTwoWheelerPolicyInitial } =
  twoWheelerSlice.actions;

export default twoWheelerSlice.reducer;
