import React from "react";
import BannerRight from "../../assets/BannerRight.jpg";
import GetStarted from "../../assets/GetStarted.jpg";
import { MdPhone, MdEmail } from "react-icons/md";
export interface CompareInterface {
  title: String;
  paragraph: String[];
  name: String;
  importance: String;
}
const Definition: React.FC<CompareInterface> = (data) => {
  return (
    <div className="Definition">
      <div className="Definition_Content">
      <div className="Definition_Left">
        <div className="Definition_Left_Top">
          <div className="Definition_Left_Top_Left">
            <h1>{data.title}</h1>
            {data.paragraph.map((dat) => (
              <p>{dat}</p>
            ))}
          </div>
          <div className="Definition_Left_Top_Right">
            <img src={GetStarted} alt="Get started" />
            <div className="Definition_Left_Top_Right_Button"></div>
          </div>
        </div>

        <div className="Definition_Left_Bottom">
          <h1>
            Understanding the Importance of <span>{data.name}</span>{" "}
          </h1>
          <p>{data.importance}</p>
        </div>
      </div>
      <div className="Definition_Right">
        <img src={BannerRight} alt="Banner Right" />
        <div className="Definition_Right_Bottom">
          <p className="Definition_Right_Bottom_Heading">Need Help?</p>
          <div className="Definition_Right_Bottom_Text">
            <div className="Definition_Right_Bottom_Text_Flex">
              <MdPhone className="Definition_Right_Bottom_Icon" />
              <p>9800000000</p>
            </div>
            <div className="Definition_Right_Bottom_Text_Flex">
              <MdEmail className="Definition_Right_Bottom_Icon" />
              <p>beemalaya@gmail.com</p>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Definition;
