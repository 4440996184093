import React from "react";
import { useNavigate } from "react-router-dom";

const BlogCard = ({ blog }: any) => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate(`/blogs/${blog._id}`);
  };
  return (
    <div className="blogcontainer" onClick={onClick}>
      <div className="blogcontainer_image">
        <img src={blog.image} alt="blog" />
      </div>
      <div className="blogcontainer_content">
        <div className="header">
          <h3 dangerouslySetInnerHTML={{ __html: blog.title }} />
        </div>
        <div className="line"></div>
        <div className="created">
          <div className="date">{blog.date}</div>
          <div className="share">
            <img
              src="https://utfs.io/f/bc12e5e0-9a8b-455c-b1d7-401589f8ba68-5j8f9x.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
