import axios from "axios";
import BlogCard from "Components/Common/BlogCard";
import Navbar from "Components/Common/Navbar";
import { useEffect, useState } from "react";

// const blogs = [
  //   {
  //     title: "Blog Title",
  //     date: "2021-09-01",
  //     content: "Blog Content",
  //     image:
  //       "https://utfs.io/f/4d8ddf3d-46e9-442f-abee-b87993332652-39kyph.png",
  //   },
  //   {
  //     title: "Blog Title2",
  //     date: "2021-09-02",
  //     content: "Blog Content2",
  //     image:
  //       "https://utfs.io/f/4d8ddf3d-46e9-442f-abee-b87993332652-39kyph.png",
  //   },
  //   {
  //     title: "Blog Title3",
  //     date: "2021-09-03",
  //     content: "Blog Content3",
  //     image:
  //       "https://utfs.io/f/4d8ddf3d-46e9-442f-abee-b87993332652-39kyph.png",
  //   },
  //   {
  //     title: "Blog Title4",
  //     date: "2021-09-04",
  //     content: "Blog Content4",
  //     image:
  //       "https://utfs.io/f/4d8ddf3d-46e9-442f-abee-b87993332652-39kyph.png",
  //   },
  //   {
  //     title: "Blog Title5",
  //     date: "2021-09-05",
  //     content: "Blog Content5",
  //     image:
  //       "https://utfs.io/f/4d8ddf3d-46e9-442f-abee-b87993332652-39kyph.png",
  //   },
  // ];

const Index = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(()=>{
    axios.get('https://api.beemalaya.com/blogs').then((response)=>{
      console.log('response is ', response);
      setBlogs(response?.data)
    })
  },[])
  return (
    <>
      <Navbar />
      <div className="blogs">
        <div className="blogcontainerlist">
          <h1>blogs</h1>
          <div className="bloglist">
            {blogs.map((blog, index) => (
              <BlogCard key={index} blog={blog} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
