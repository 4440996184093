import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTwoWheelerPolicyApi } from "helpers/backend_helper";

export const getTwoWheelerPolicy = createAsyncThunk(
  "twoWheeler/getTwoWheelerPolicy",
  async () => {
    try {
      const response = getTwoWheelerPolicyApi();
      return response;
    } catch (error) {
      return error;
    }
  }
);
