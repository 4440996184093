import React from "react";
const Footer = () => {
  const getInsurancellinks = [
    {
      title: "Children Plan",
      link: "/children",
    },
    {
      title: "Term Life",
      link: "/term",
    },
    {
      title: "Whole Life",
      link: "/whole-life",
    },
    {
      title: "Endowment Plans",
      link: "/endowment",
    },
    {
      title: "Money Back",
      link: "#",
    },
    {
      title: "Husband- Wife",
      link: "#",
    },
    {
      title: "Four Wheeler",
      link: "#",
    },
    {
      title: "Two Wheeler",
      link: "/two-wheeler",
    },
    {
      title: "Travel Insurance",
      link: "/travel",
    },
  ];
  const getquickllinks = [
    {
      title: "Home",
      link: "#",
    },
    {
      title: "product",
      link: "#",
    },
    {
      title: "About Us",
      link: "#",
    },

    {
      title: "Blogs",
      link: "/blog",
    },
  ];

  return (
    <div className="footer">
      <div className="footer_content">
        <div className="footer_links">
          <div className="footer_links_beemalaya">
            <div className="companylogo">
              <img src="https://utfs.io/f/459d8bb9-5634-4276-9a14-34a920d63209-1z9s6a.svg" alt="" />
              <div className="companyname">Beemalaya</div>
            </div>
            <div className="sociallinks">
              <a href="https://www.facebook.com/profile.php?id=61554033119410" target="_blank">
                <img src="https://utfs.io/f/a5dd5ac1-595e-44a7-93e1-871b4e7fdfde-87z83q.svg" alt="" /></a>
              <a href="https://www.instagram.com/beemalaya_insurance/">
                <img src="https://utfs.io/f/de9797da-5535-46f3-9b28-a27f9e5f009d-h7hyq.svg" alt="" />

              </a>


              <img src='https://utfs.io/f/2a870f17-a397-4bfc-95e5-d50e4b850066-f5khal.svg' alt="" />
              <img src="https://utfs.io/f/30512a71-6891-4b99-a540-d5ad66a0fda6-gegjj1.svg" alt="" />
            </div>
          </div>
          <div className="links">
            <div className="links_getinsured">
              <div className="links_getinsured_header">Get insured</div>
              <div className="get_insurance">
                {getInsurancellinks.map((item, index) => (
                  <a
                    key={index}
                    href={item.link}
                    className="get_insurance_item"
                  >
                    {item.title}
                  </a>
                ))}
              </div>
            </div>
            <div className="links_quick">
              <div className="links_quick_header">Quick Links</div>
              <div className="get_insurance">
                {getquickllinks.map((item, index) => (
                  <a
                    key={index}
                    href={item.link}
                    className="get_insurance_item"
                  >
                    {item.title}
                  </a>
                ))}
              </div>
            </div>
            <div className="links_contact">
              <div className="links_getinsured_header">Contact Us</div>
              <div className="get_insurance">
                <div id="footer_directcontact" className="get_insurance_item">
                  <img src=" https://utfs.io/f/de76126f-4291-47a6-aaf4-0c0d36609361-1taam.svg" alt="" />
                  <div className="directcontact">+977-9768448066</div>
                </div>
                <div id="footer_directcontact" className="get_insurance_item">
                  <img src="https://utfs.io/f/82dd903f-6c10-40c1-a084-6021687bc640-1liw0s.svg" alt="" />
                  <div className="directcontact">beemalaya.1@gmail.com</div>
                </div>
                <div id="footer_directcontact" className="get_insurance_item">
                  <img src="https://utfs.io/f/39b5a404-09c7-4350-a3d1-2e41996b9e5e-vftynp.svg" alt="" />
                  <div className="directcontact">Pulchowk, Lalitpur</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_terms">
          <div className="footer_terms_desc">
            The insurance plans and options presented on this Website are
            provided by various insurance providers and are subject to their
            terms and conditions. Please read scheme related documents
            carefully.
          </div>
          <div className="line"></div>
          <div className="footer_terms_list">
            <div className="listelement">
              © 2024 Beemalaya, All Rights Reserved.
            </div>
            <div className="listelement">Terms and Conditions</div>
            <div className="listelement">Policy Statement</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
