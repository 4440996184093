import React from "react";
import OurMission from "assets/OurMission.png";
import OurVision from "assets/OurVision.png";
import OurMissionSvg from "assets/OurMission.svg";
const PlanVision = () => {
  return (
    <div className="PlanVision">
      <div className="PlanVision_Content">
        <div className="PlanVision_Content_Box">
          <img
            className="PlanVision_Content_Box_Image"
            src={OurMission}
            alt="Our Mission"
          />
          {/* <img src={OurMissionSvg} alt="Our Mission" /> */}
          <div className="PlanVision_Content_Box_Box">
            <h1>Our Mission</h1>
            <p>
            Our mission is to leverage technology to suggest the best policies for the customers.
            </p>
          </div>
          <div className="PlanVision_Content_Box_Box1"></div>
        </div>
        <div className="PlanVision_Content_Box">
          <img
            className="PlanVision_Content_Box_Image"
            src={OurVision}
            alt="Our Vision"
          />
          {/* <img src={OurMissionSvg} alt="Our Mission" /> */}
          <div className="PlanVision_Content_Box_Box">
            <h1>Our Vision</h1>
            <p>
            Our vision is to help individual buys the informed policy that provide them with best coverage.
            </p>
          </div>
          <div className="PlanVision_Content_Box_Box1"></div>
        </div>
      </div>
    </div>
  );
};

export default PlanVision;
