import {
    calculateAgeFromDate,
    calculateDayDifference,
    getPriceByAgeAndDays,

  } from "helpers/function_helper";
import { getTravelData } from "helpers/sessionStorageHelper";
  import React, { useEffect, useState } from "react";

  const TravelPolicyCard: React.FC<any> = ({ policy }) => {
    const travelData = getTravelData()
  const [showModal, setShowModal] = useState(false);
  const [premiumPrice, setPremiumPrice] = useState(0);
  useEffect(()=>{
    const age = calculateAgeFromDate(travelData.dateOfBirth)
    const range = calculateDayDifference(travelData.startDate, travelData.endDate)
    const price = getPriceByAgeAndDays(policy, age, range)
    
    
    if(price){
      const total = Math.round(price * 133.73) + parseInt((policy.stampCost ? policy?.stampCost : '0'))
      setPremiumPrice(total)
    }
  },[policy])

    return (
      <div className="cardcontainer_card_insurance">
        <div className="cardcontainer_card_insurance_company">
          <img className="companylogo" src={policy.companyLogo} alt="" />
          <div className="companyname">{policy.company}</div>
        </div>
        <div className="cardcontainer_card_insurance_policycontent">
          <div className="policyinfo">
            <div>
              <div className="policyinfo_title"> Policy name:</div>
              <div className="policyinfo_titleresult">
                Travel Medical Insurance
              </div>
            </div>
            <div>
              <span className="policyinfo_title">Premium amount</span>
              <span className="policyinfo_titleresult">Rs {premiumPrice}</span>
            </div>
          </div>
  
          {/* <div className="premiumamount">
            <div className="button-primary-2">View details</div>
            <div className="button-primary-2">Select Plan</div>
          </div> */}
        </div>
      </div>
    );
  };
  
  export default TravelPolicyCard;
  