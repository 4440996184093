import React from "react";
const Contact = () => {
  return (
    <div className="contact">
      <div className="contact_container">
        <div className="contactinfo">
          <div className="contactinfo_header">
            Question? <span>We’re Here to Help!</span>
          </div>
          <div className="contactinfo_desc">
            Connect with our caring customer support team for attentive
            assistance. Call, request a call back or email us - we’re here for
            you!
          </div>
          <div className="contactinfo_direct">
            <div className="number">
              <img src="https://utfs.io/f/de76126f-4291-47a6-aaf4-0c0d36609361-1taam.svg" alt="call" />
              <div className="content">9768448066</div>
            </div>
            <div className="email">
              <img src="https://utfs.io/f/82dd903f-6c10-40c1-a084-6021687bc640-1liw0s.svg" alt="email" />
              <div className="content">beemalaya.1@gmail.com</div>
            </div>
          </div>
          <div className="contactinfo_connect">
            <div className="header">Connect with us</div>

            <div className="logo">
              <a href="https://www.facebook.com/profile.php?id=61554033119410" target="_blank">
                <img src="https://utfs.io/f/a5dd5ac1-595e-44a7-93e1-871b4e7fdfde-87z83q.svg" alt="facebook" />

              </a>
              <a href="https://www.instagram.com/beemalaya_insurance/">
                <img src="https://utfs.io/f/de9797da-5535-46f3-9b28-a27f9e5f009d-h7hyq.svg" alt="instagram" />

              </a>
              <img src="https://utfs.io/f/2a870f17-a397-4bfc-95e5-d50e4b850066-f5khal.svg" alt="twitter" />
              <img src=" https://utfs.io/f/30512a71-6891-4b99-a540-d5ad66a0fda6-gegjj1.svg" alt="youtube" />
            </div>
          </div>
        </div>
        <form className="contact_container_form">
          <div className="contactname">
            <img src="https://utfs.io/f/aa132f57-36c1-4d1b-9b69-f0976c05b3ac-5482fr.svg" alt="" className="profilelogo" />
            <div>
              <input type="text" placeholder="Full Name" />
            </div>
          </div>
          <div className="contactemail">
            <img src="https://utfs.io/f/bc94d953-7546-4a4f-8af9-48279d39fea3-1taam.svg" alt="" className="emaillogo" width={42} height={42} />
            <div>
              <input type="number" placeholder="Phone Number" />
            </div>
          </div>

            <div className="contactmessage">
             <textarea placeholder="Message"></textarea>
            </div>
   
          <div className="contactsubmit">
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
