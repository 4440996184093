import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

interface ThirdPartyInterface {
  validation: any;
  setTab: any;
}
const ThirdParty: React.FC<ThirdPartyInterface> = ({ validation, setTab }) => {
  const validation1: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      category: "",
      capacity: "",
    },

    validationSchema: Yup.object({
      category: Yup.string().required("Please Select a Category"),
      capacity: Yup.string().required("Please Enter Capacity"),
    }),
    onSubmit: (values) => {
      setTab(3);
    },
  });
  return (
    <div className="Form">
      <div className="Form_Heading">
        <h1>Create a Two Wheeler Insurance Quote</h1>
      </div>
      <div className="Form_Body">
        <div className="Form_Body_Heading">
          <p>Bike Details</p>
          <div className="Form_Body_Heading_Container">
            <div
              className="Form_Body_Heading_Progress"
              style={{ width: "66%" }}
            ></div>
          </div>
          <span>Step 2 of 3</span>
        </div>
        <div className="Form_Body_Body">
          <div className="Form_Body_Body_InputBox">
            <p>Category *</p>
            <select
              className={
                validation1.touched.category && validation1.errors.category
                  ? "Form_Body_Body_InputDanger"
                  : "Form_Body_Body_Input"
              }
              onChange={(e) => {
                // validation.setFieldValue("gender", e.target.name);
                validation.setFieldValue("category", e.target.value);
                validation1.setFieldValue("category", e.target.value);
              }}
            >
              <option value="">Category</option>
              <option value="Electric Vehicle">Electric Vehicle</option>
              <option value="Motor Cycle">Motor Cycle</option>
              <option value="Scooter">Scooter</option>
            </select>
            {validation1.touched.category && validation1.errors.category && (
              <div className="text-danger">{validation1.errors.category}</div>
            )}
          </div>
          <div className="Form_Body_Body_InputBox">
            <p>Capacity *</p>
            <input
              className={
                validation1.touched.capacity && validation1.errors.capacity
                  ? "Form_Body_Body_InputDanger"
                  : "Form_Body_Body_Input"
              }
              type="text"
              placeholder="Cubic Capacity (CC) / Kilowatt"
              onChange={(e) => {
                validation.setFieldValue("capacity", e.target.value);
                validation1.setFieldValue("capacity", e.target.value);
              }}
            />
            {validation1.touched.capacity && validation1.errors.capacity && (
              <div className="text-danger">{validation1.errors.capacity}</div>
            )}
          </div>
        </div>
        <div className="Form_Body_Button">
          <button
            type="submit"
            className="contactsubmit1"
            onClick={() => {
              setTab(1);
            }}
          >
            Back
          </button>
          <button
            type="submit"
            className="contactsubmit1"
            onClick={() => {
              validation1.handleSubmit();
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThirdParty;
