import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import rootReducer, { RootState } from './slices'; // Adjust the import path for your rootReducer

// Define a Thunk type
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// Create the Redux store
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializable check for now, to allow thunk actions
    }),
});

export default store;

// Export the RootState type for use in components or thunks
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;