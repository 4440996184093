import Contact from "./../../Components/landing/Contact";
import Landing from "./../../Components/landing/landing";
import ProtectBanner from "./../../Components/landing/protectbanner";
import Testimonial from "./../../Components/landing/Testimonials";
import WhyChooseUs from "./../../Components/landing/Whychooseus";
import Footer from "./../../Components/landing/Footer";
import Navbar from "Components/Common/Navbar";
import { Helmet } from "react-helmet-async";

const LandingContainer = () => {
  return (
    <>
      <Helmet>
        <title>
          Beemalaya | Compare and find best insurance policies in Nepal
        </title>
        <meta
          name="description"
          content="Get unbiased comparisons of top insurance providers in Nepal to find the perfect policy for you in minutes. Save time on your coverage!"
        />
      </Helmet>
      <Navbar />
      <Landing />
      <WhyChooseUs />

      <ProtectBanner />
      <Testimonial />
      <Contact />
      <Footer />
    </>
  );
};

export default LandingContainer;
