import React from "react";
import Navbar from "../../Components/Common/Navbar";
import Banner, { BannerDataInterface } from "../layouts/Banner";
import Definition, { CompareInterface } from "testPages/layouts/Definition";
import Offers, { OfferInterface } from "testPages/layouts/Offers";
import CostEffective from "../../assets/costEffective.svg";
import FinancialProtection from "../../assets/financialProtection.svg";
import Flexibility from "../../assets/flexibility.svg";
import Affordability from "../../assets/affordability.svg";
import LifeStage from "../../assets/lifeStage.svg";
import CoverageAmount from "../../assets/CoverageAmount.png";
import ProtectionDuration from "../../assets/ProtectionDuration.png";
import FinancialSecurity from "../../assets/FinancialSecurity.png";
import PeaceOfMind from "../../assets/PeaceOfMind.png";
import Works, { WorkInterface } from "testPages/layouts/Works";
import FAQs, { FAQItem } from "testPages/layouts/FAQs";
import Footer from "Components/landing/Footer";
import Contact from "Components/landing/Contact";
import EndowmentPlan from "pages/Life/EndowmentPlan";
import { Helmet } from "react-helmet-async";

interface EndowmentDataInterface {
  banner: BannerDataInterface;
  compare: CompareInterface;
  offers: OfferInterface;
  works: WorkInterface;
  faqData: FAQItem[];
}
const EndowmentData: EndowmentDataInterface = {
  banner: {
    name: "Endowment Life Insurance",
    link: "/endowment-life-insurance-quote",
  },
  compare: {
    title: "How to compare Endowment policy?",
    paragraph: [
      "1. Enter your age.",
      "2. Enter the term you want to be insured for.",
      "3. Enter the sum assured",
      "4. Compare between policies.",
      "5. View details.",
    ],
    name: "Endowment Life Insurance",
    importance:
      "Endowment Life Insurance provides both a death benefit and a savings or investment component.",
  },
  offers: {
    policyName: "Endowment Life Insurance Policy",
    offerData: [
      {
        image: CostEffective,
        text: "Life Insurance Coverage",
      },
      {
        image: FinancialProtection,
        text: "Savings and Investment",
      },
      {
        image: Affordability,
        text: "Maturity Benefits",
      },
      {
        image: Flexibility,
        text: "Long-Term Financial Planning",
      },
    ],
  },
  works: {
    policyName: "Endowment Life Insurance Policy",
    insurance: "Endowment Life Insurance",
    workData: [
      {
        id: "01",
        image: LifeStage,
        text: "Age 35, facing financial responsibilities like mortgage, children's education, etc.",
        heading: "Life Stage",
      },
      {
        id: "02",
        image: CoverageAmount,
        text: "NRS 500,000 policy ensures substantial support for beneficiaries.",
        heading: "Coverage Amount",
      },
      {
        id: "03",
        image: ProtectionDuration,
        text: "20-year term aligns with family's reliance on income.",
        heading: "Protection Duration",
      },
      {
        id: "04",
        image: FinancialSecurity,
        text: "Payout aids family's financial stability in case of death.",
        heading: "Financial Security",
      },
      {
        id: "05",
        image: PeaceOfMind,
        text: "Family's financial security brings peace, enabling joyful living.",
        heading: "Peace of Mind",
      },
    ],
  },
  faqData: [
    {
      id: 1,
      title: "What is an endowment insurance policy?",
      description: [
        "An endowment insurance policy is a type of life insurance policy that provides both a death benefit and a savings component.",
        "It combines life insurance coverage with a savings or investment feature. If the policyholder passes away during the policy term, the death benefit is paid out to the designated beneficiaries. If the policyholder survives until the end of the policy term, a maturity benefit is paid out, which is a lump sum that includes the accumulated savings or investment component.",
      ],
    },
    {
      id: 2,
      title: "How does an endowment insurance policy work?",
      description: [
        "Endowment insurance policies work by providing life insurance coverage and a savings or investment component. Policyholders pay premiums for a specified policy term.",
        "If the policyholder passes away during the term, the death benefit is paid out to the beneficiaries. If the policyholder survives until the end of the term, a maturity benefit is paid out, which includes the sum assured and the accumulated savings or investment portion of the policy.",
      ],
    },
    {
      id: 3,
      title: "What are the benefits of an endowment insurance policy?",
      description: [
        "Endowment insurance policies offer several benefits, including:",
        "a) Life insurance coverage: The policy provides a death benefit to the beneficiaries in case of the policyholder's death during the policy term.",
        "b) Savings or investment growth: The policy's savings or investment component accumulates over time, providing a lump sum payout at maturity.",
        "c) Maturity benefit: If the policyholder survives until the end of the term, a lump sum maturity benefit is paid out, which can be used for various financial needs, such as retirement planning or funding educational expenses.",
        "d) Tax advantages: The proceeds from an endowment insurance policy may be eligible for tax benefits, depending on the tax laws",
      ],
    },
    {
      id: 4,
      title: "Can endowment insurance policies be customized?",
      description: [
        "Endowment insurance policies can often be customized to suit individual needs. Policyholders can typically choose the sum assured, policy term, and premium payment frequency according to their requirements.",
        "Additionally, some policies may offer optional riders or additional coverage options, such as critical illness coverage or disability benefits, to enhance the policy's protection.",
      ],
    },
  ],
};
const EndowmentLifeInsurance = () => {
  const { banner, compare, offers, works, faqData } =
    EndowmentData as EndowmentDataInterface;

  return (
    <>
      <Helmet>
        <title>
          Beemalaya | Compare And Find Endowment Life Insurance Policies in
          Nepal
        </title>
        <meta
          name="description"
          content="Find the best Endowment Life insurance Policy from Nepal's top insurer that suits you before you buy it.
          "
        />
      </Helmet>
      <Navbar />
      {/* <div className="Layout"> */}
      <EndowmentPlan />
      {/* <Banner {...banner} /> */}
      {/* <Definition {...compare} /> */}
      <Offers {...offers} />
      <Works {...works} />
      <FAQs data={faqData} />
      <Contact />
      <Footer />
      {/* </div> */}
    </>
  );
};

export default EndowmentLifeInsurance;
