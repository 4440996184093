interface GoogleConfig {
  API_KEY: string;
  CLIENT_ID: string;
  SECRET: string;
}

interface FacebookConfig {
  APP_ID: string;
}

interface ApiConfig {
  API_URL: string;
}
interface SocialLinks {
  facebook: string;
}
interface Config {
  google: GoogleConfig;
  facebook: FacebookConfig;
  api: ApiConfig;
  social: SocialLinks;
}

const config: Config = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    API_URL: "https://api.beemalaya.com/",
    // API_URL: "http://localhost:8000",
  },
  social: {
    facebook: "https://www.facebook.com/profile.php?id=61554033119410",
  },
};

export default config;
