import React from "react";
import Navbar from "../../Components/Common/Navbar";
import Banner, { BannerDataInterface } from "../layouts/Banner";
import Definition, { CompareInterface } from "testPages/layouts/Definition";
import Offers, { OfferInterface } from "testPages/layouts/Offers";
import CostEffective from "../../assets/costEffective.svg";
import FinancialProtection from "../../assets/financialProtection.svg";
import Flexibility from "../../assets/flexibility.svg";
import Affordability from "../../assets/affordability.svg";
import LifeStage from "../../assets/lifeStage.svg";
import CoverageAmount from "../../assets/CoverageAmount.png";
import ProtectionDuration from "../../assets/ProtectionDuration.png";
import FinancialSecurity from "../../assets/FinancialSecurity.png";
import PeaceOfMind from "../../assets/PeaceOfMind.png";
import Works, { WorkInterface } from "testPages/layouts/Works";
import FAQs, { FAQItem } from "testPages/layouts/FAQs";
import Footer from "Components/landing/Footer";
import Contact from "Components/landing/Contact";
import { Helmet } from "react-helmet-async";
import ChildrenLifeInsuranceForm from "pages/Life/ChildrenPlan";
interface ChildrenDataInterface {
  banner: BannerDataInterface;
  compare: CompareInterface;
  offers: OfferInterface;
  works: WorkInterface;
  faqData: FAQItem[];
}
const ChildrenData: ChildrenDataInterface = {
  banner: {
    name: "Children Life Insurance",
    link: "/children-life-insurance-quote",
  },
  compare: {
    title: "How to compare Children policy?",
    paragraph: [
      "1. Enter your age.",
      "2. Enter the term you want to be insured for.",
      "3. Enter the sum assured",
      "4. Compare between policies.",
      "5. View details.",
    ],
    name: "Children Life Insurance",
    importance:
      "Children Life Insurance provide financial protection and savings for the future needs of children.",
  },
  offers: {
    policyName: "Children Life Insurance Policy",
    offerData: [
      {
        image: CostEffective,
        text: "Financial Protection",
      },
      {
        image: FinancialProtection,
        text: "Education Funding",
      },
      {
        image: Affordability,
        text: "Savings and Investment",
      },
      {
        image: Flexibility,
        text: "Long-Term Financial Planning",
      },
    ],
  },
  works: {
    policyName: "Children Life Insurance Policy",
    insurance: "Children Life Insurance",
    workData: [
      {
        id: "01",
        image: LifeStage,
        text: "Age 35, facing financial responsibilities like mortgage, children's education, etc.",
        heading: "Life Stage",
      },
      {
        id: "02",
        image: CoverageAmount,
        text: "NRS 500,000 policy ensures substantial support for beneficiaries.",
        heading: "Coverage Amount",
      },
      {
        id: "03",
        image: ProtectionDuration,
        text: "20-year term aligns with family's reliance on income.",
        heading: "Protection Duration",
      },
      {
        id: "04",
        image: FinancialSecurity,
        text: "Payout aids family's financial stability in case of death.",
        heading: "Financial Security",
      },
      {
        id: "05",
        image: PeaceOfMind,
        text: "Family's financial security brings peace, enabling joyful living.",
        heading: "Peace of Mind",
      },
    ],
  },
  faqData: [
    {
      id: 1,
      title: "What is a child insurance policy?",
      description: [
        "A child insurance policy is a type of insurance policy specifically designed to provide financial protection for children. It typically offers a combination of insurance coverage and savings or investment component.",
        "The policy is purchased by parents or guardians on behalf of their child, and it aims to provide financial security for the child's future needs, such as education expenses, marriage, or other financial goals.",
      ],
    },
    {
      id: 2,
      title: "What does a child insurance policy cover?",
      description: [
        "Child insurance policies generally offer the following coverage:",
        "a) Life insurance: In the unfortunate event of the parents death, the policy provides a death benefits to the children or vice versa",
        "b) Savings or investment component: The policy accumulates a cash value over time, which can be used to meet future financial needs, such as education expenses or other planned milestones.",
      ],
    },
    {
      id: 3,
      title: "What are the benefits of a child insurance policy?",
      description: [
        "Child insurance policies offer several benefits, including:",
        "a) Financial protection: The policy provides a safety net to the child's family in case of an unfortunate event like the child's death.",
        "b) Savings or investment growth: The policy's cash value component helps accumulate savings or investments for the child's future needs.",
        "c) Fixed premium: Child insurance policies often come with fixed premiums throughout the policy term, ensuring long-term affordability.",
      ],
    },
    {
      id: 4,
      title: "Can child insurance policies be customized?",
      description: [
        "Yes, child insurance policies can often be customized to meet specific needs. Policyholders can typically choose the coverage amount, policy duration, and premium payment term based on their requirements.",
        "Additionally, some policies offer flexibility in terms of adding riders or additional coverage to enhance the policy's protection.",
      ],
    },
  ],
};
const ChildrenLifeInsurance = () => {
  const { banner, compare, offers, works, faqData } =
    ChildrenData as ChildrenDataInterface;

  return (
    <>
    <Helmet>
        <title>
        Beemalaya | Compare and find Best Children Life Insurance Policies in Nepal
        </title>
        <meta
          name="description"
          content="Find the best Children Life insurance Policy from Nepal's top insurer that suits you before you buy it.
      "
        />
      </Helmet>
      <Navbar />
      {/* <div className="Layout"> */}
      {/* <Banner {...banner} /> */}
      {/* <Definition {...compare} /> */}
      <ChildrenLifeInsuranceForm/>
      <Offers {...offers} />
      <Works {...works} />
      <FAQs data={faqData} />
      <Contact/>
      <Footer />
      {/* </div> */}
    </>

  );
};

export default ChildrenLifeInsurance;
