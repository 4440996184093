

export const getTravelData = () => {
    const travelDataJson = localStorage.getItem('travelData')
    const travelData = travelDataJson && JSON.parse(travelDataJson)
    return travelData
}


export const getTwoWheelerData = () => {
    const twoWheelerDataJson = sessionStorage.getItem('twoWheelerData')
    const twoWheelerData = twoWheelerDataJson && JSON.parse(twoWheelerDataJson)
    return twoWheelerData
}

export const getFourWheelerData = () => {
    const fourWheelerDataJson = sessionStorage.getItem('fourWheelerData')
    const fourWheelerData = fourWheelerDataJson && JSON.parse(fourWheelerDataJson)
    return fourWheelerData
}
export const getLifeInsuranceData = () => {
    const lifeInsuranceDataJson = sessionStorage.getItem('termLifeData')
    const lifeInsuranceData = lifeInsuranceDataJson && JSON.parse(lifeInsuranceDataJson)
    return lifeInsuranceData
}