import React from "react";
import LargeNumber from "assets/LargeNumberOfInsurance.svg";
import UnbiasedComparison from "assets/UnbiasedComparison.svg";
import CustomizedInsurance from "assets/CustomizedInsuranceQuotes.svg";
const WhyChooseUs = () => {
  return (
    <div className="WhyChooseUs">
      <h1>Why Choose Us?</h1>
      <div className="WhyChooseUs_Box">
        <div className="WhyChooseUs_BoxWrapper">
          <img
            src={LargeNumber}
            className="WhyChooseUS_Box_Image"
            alt="Large number of insurance policies"
          />
          <div className="WhyChooseUs_Box_Box">
            <h3>Large number of insurance policies</h3>
            <p>
            Our dedicated members have collected large number of insurance policies.
            </p>
          </div>
        </div>
        <div className="WhyChooseUs_BoxWrapper1">
          <div className="WhyChooseUs_Box_Box">
            <h3>Unbiased Comparison</h3>
            <p>
            We offer unbiased comparison between different insurance policies.
            </p>
          </div>
          <img
            src={UnbiasedComparison}
            className="WhyChooseUS_Box_Image"
            alt="Large number of insurance policies"
          />
        </div>
        <div className="WhyChooseUs_BoxWrapper">
          <img
            src={CustomizedInsurance}
            className="WhyChooseUS_Box_Image"
            alt="Customized Insurance Quotes"
          />
          <div className="WhyChooseUs_Box_Box">
            <h3>Customized Insurance Quotes</h3>
            <p>
            We help you get customized quotes that best suits your requirements.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
