import React from "react";
import landingbanner from "../../assets/landingbanner.svg";
import TermLife from "./../../assets/insurance/termlife.svg";
import Endowment from "./../../assets/insurance/endowment.svg";
import WholeLife from "./../../assets/insurance/wholelife.svg";
import MoneyBack from "./../../assets/insurance/moneyback.svg";
import ChildrenPlan from "./../../assets/insurance/childrenplan.svg";
import travel from "./../../assets/insurance/travel.svg";
import twowheeler from "./../../assets/insurance/two-wheeler.svg";
import { Link, useNavigate } from "react-router-dom";
import LandingRight from "../../assets/LandingRight.png";
const Landing = () => {
  const navigate = useNavigate();
  const insurance = [
    {
      title: "Term Life",
      logo: "https://utfs.io/f/56ec2306-7008-47ad-b85f-169f4c486a25-jj8g48.svg",
      width: "35px",
      height: "58px",
      link: "/term",
    },
    {
      title: "Endowment",
      logo: "https://utfs.io/f/b0092eb7-68db-49eb-8f74-292bc2f7911b-v47okf.svg",
      width: "56px",
      height: "58px",
      link: "/endowment",
    },
    {
      title: "Whole Life",
      logo: "https://utfs.io/f/b18ec4ac-1509-4edc-8a1d-157ab0fd45c8-e4bijx.svg",
      width: "61px",
      height: "58px",
      link: "/whole-life",
    },
    {
      title: "Children Plan",
      logo: 'https://utfs.io/f/b08401ce-c4e0-48a6-89d7-ea252226f3d9-2ytprc.svg',
      width: "90px",
      height: "58px",
      link: "/children",
    },
    {
      title: "Travel",
      logo: 'https://utfs.io/f/71200eec-5525-43d2-8255-6a7eb38783f7-ebewva.svg',
      width: "58px",
      height: "58px",
      link: "/travel",
    },
    {
      title: "Two-wheeler",
      logo: 'https://utfs.io/f/8aea66e5-251a-40a6-abc7-9d74270d7607-8t2cl5.svg',
      width: "94.6px",
      height: "58px",
      link: "/two-wheeler",
    },
  ];
  return (
    <div className="landing">
      <div className="landing_cover">
        <div className="landing_content">
          <div className="content">
            <div className="content_header">
              Compare and find  the<br/>  <span>best insurance</span> policy{" "}
              <span></span>
            </div>
          </div>
          <div className="landing_content_insurance">
            <div className="container">
              {insurance.map((item, index) => {
                return (
                  <div
                    className="container_insurance"
                    key={index}
                    onClick={() => {
                      navigate(item.link);
                    }}
                  >
                    <div className="insurance_logo">
                      <img
                        src={item.logo}
                        alt={item.title}
                        width={item.width}
                        height={item.height}
                      />
                    </div>
                    <div className="insurance_title">{item.title}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="landing_banner">
          <img src="https://utfs.io/f/2578cbd8-1da8-475f-84e2-22ae4ee060f4-ompr7n.svg" alt="" className="landing_banner_left"/>
          <img src="https://utfs.io/f/7837452e-1ba3-4e21-a098-613b675b0ec7-qik7mj.png" alt="" className="landing_banner_right" />
        </div>
      </div>
    </div>
  );
};

export default Landing;
