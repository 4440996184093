import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

interface PolicyDetailsInterface {
  type: string;
  setTab: any;
  validation: any;
  max?: number;
  min?: number;
  minTerm?: number;
  maxTerm?: number;
}
const PolicyDetails: React.FC<PolicyDetailsInterface> = ({
  type,
  validation,
  setTab,
  max = 65,
  min = 5,
  minTerm = 5,
  maxTerm = 25,
}) => {
  const validation1: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      sumAssured: "",
      term: "",
      dateOfBirth: "",
    },

    validationSchema: Yup.object({
      sumAssured: Yup.number().required("Please select sum assured").min(20000, "Value must be greater than or equal to 20000"),
      term: Yup.string().required("Please select a term"),
      dateOfBirth: Yup.string().required("Please select date of birth"),
    }),
    onSubmit: (values) => {
      setTab(2);
    },
  });
  const options = Array.from({ length: max - min + 1 }, (_, i) => min + i);
  const optionsTerm = Array.from(
    { length: maxTerm - minTerm + 1 },
    (_, i) => minTerm + i
  );
  return (
    <div className="Form">
      <div className="Form_Heading">
        <h1>{type}</h1>
      </div>
      <div className="Form_Body">
        <div className="Form_Body_Heading">
          <p>Policy Details</p>
          <div className="Form_Body_Heading_Container">
            <div
              className="Form_Body_Heading_Progress"
              style={{ width: "50%" }}
            ></div>
          </div>
          <span>Step 1 of 2</span>
        </div>
        <div className="Form_Body_Body">
          <div className="Form_Body_Body_InputBox">
            <p>Sum Assured</p>
            <input
              className={
                validation1.touched.sumAssured && validation1.errors.sumAssured
                  ? "Form_Body_Body_InputDanger"
                  : "Form_Body_Body_Input"
              }
              min={20000}
              
              type="number"
              placeholder="Sum Assured"
              onChange={(e) => {
                // validation.setFieldValue("gender", e.target.name);
                validation.setFieldValue("sumAssured", e.target.value);
                validation1.setFieldValue("sumAssured", e.target.value);
              }}
            />
            {validation1.touched.sumAssured &&
              validation1.errors.sumAssured && (
                <div className="text-danger">
                  {validation1.errors.sumAssured}
                </div>
              )}
          </div>
          <div className="Form_Body_Body_InputBox">
            <p>Age Of Insured *</p>
            <select
              className={
                validation1.touched.dateOfBirth &&
                validation1.errors.dateOfBirth
                  ? "Form_Body_Body_InputDanger"
                  : "Form_Body_Body_Input"
              }
              onChange={(e) => {
                // validation.setFieldValue("gender", e.target.name);

                validation.setFieldValue("dateOfBirth", e.target.value);
                validation1.setFieldValue("dateOfBirth", e.target.value);
              }}
            >
              <option value="">Select age</option>

              {/* Generate options from 5 to 65 */}
              {options.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>

            {validation1.touched.dateOfBirth &&
              validation1.errors.dateOfBirth && (
                <div className="text-danger">
                  {validation1.errors.dateOfBirth}
                </div>
              )}
          </div>
          <div className="Form_Body_Body_InputBox">
            <p>Term *</p>
            <select
              className={
                validation1.touched.term && validation1.errors.term
                  ? "Form_Body_Body_InputDanger"
                  : "Form_Body_Body_Input"
              }
              onChange={(e) => {
                // validation.setFieldValue("gender", e.target.name);
                validation.setFieldValue("term", e.target.value);
                validation1.setFieldValue("term", e.target.value);
              }}
            >
              <option value="">Select Term</option>
              {optionsTerm.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
            {validation1.touched.term && validation1.errors.term && (
              <div className="text-danger">{validation1.errors.term}</div>
            )}
          </div>
        </div>
        <div className="Form_Body_Button">
          <div></div>
          <button
            type="submit"
            className="contactsubmit1"
            onClick={() => {
              validation1.handleSubmit();
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default PolicyDetails;
