import React from "react";
import Navbar from "../../Components/Common/Navbar";
import Banner, { BannerDataInterface } from "../layouts/Banner";
import Definition, { CompareInterface } from "testPages/layouts/Definition";
import Offers, { OfferInterface } from "testPages/layouts/Offers";
import CostEffective from "../../assets/costEffective.svg";
import FinancialProtection from "../../assets/financialProtection.svg";
import Flexibility from "../../assets/flexibility.svg";
import Affordability from "../../assets/affordability.svg";
import LifeStage from "../../assets/lifeStage.svg";
import CoverageAmount from "../../assets/CoverageAmount.png";
import ProtectionDuration from "../../assets/ProtectionDuration.png";
import FinancialSecurity from "../../assets/FinancialSecurity.png";
import PeaceOfMind from "../../assets/PeaceOfMind.png";
import Works, { WorkInterface } from "testPages/layouts/Works";
import FAQs, { FAQItem } from "testPages/layouts/FAQs";
import Footer from "Components/landing/Footer";
import Contact from "Components/landing/Contact";
import TravelForm from "pages/Travel";
import { Helmet } from "react-helmet-async";
interface EndowmentDataInterface {
  banner: BannerDataInterface;
  compare: CompareInterface;
  offers: OfferInterface;
  works: WorkInterface;
  faqData: FAQItem[];
}
const EndowmentData: EndowmentDataInterface = {
  banner: {
    name: "Travel Insurance",
    link: "/travel-quote",
  },
  compare: {
    title: "How to compare Travel Insurance policy?",
    paragraph: [
      "1. Enter your age.",
      "2. Enter the term you want to be insured for.",
      "3. Enter the sum assured",
      "4. Compare between policies.",
      "5. View details.",
    ],
    name: "Travel Insurance",
    importance:
      "Travel Insurance provides financial protection and assistance for various risks and emergencies that may occur while traveling",
  },
  offers: {
    policyName: "Travel Insurance Policy",
    offerData: [
      {
        image: CostEffective,
        text: "Trip Cancellation and Interruption",
      },
      {
        image: FinancialProtection,
        text: "Emergency Medical Expenses",
      },
      {
        image: Affordability,
        text: "Baggage and Personal Belongings",
      },
      {
        image: Flexibility,
        text: "Personal Liability",
      },
    ],
  },
  works: {
    policyName: "Travel Insurane Policy",
    insurance: "Travel Insurance",
    workData: [
      {
        id: "01",
        image: LifeStage,
        text: "Age 35, facing financial responsibilities like mortgage, children's education, etc.",
        heading: "Life Stage",
      },
      {
        id: "02",
        image: CoverageAmount,
        text: "NRS 500,000 policy ensures substantial support for beneficiaries.",
        heading: "Coverage Amount",
      },
      {
        id: "03",
        image: ProtectionDuration,
        text: "20-year term aligns with family's reliance on income.",
        heading: "Protection Duration",
      },
      {
        id: "04",
        image: FinancialSecurity,
        text: "Payout aids family's financial stability in case of death.",
        heading: "Financial Security",
      },
      {
        id: "05",
        image: PeaceOfMind,
        text: "Family's financial security brings peace, enabling joyful living.",
        heading: "Peace of Mind",
      },
    ],
  },
  faqData: [],
};
const Travel = () => {
  const { banner, compare, offers, works, faqData } =
    EndowmentData as EndowmentDataInterface;

  return (
    <>
      <Helmet>
        <title>
        Beemalaya | Compare and find Best Travel Insurance Policies in Nepal
        </title>
        <meta
          name="description"
          content="Find the best Travel insurance Policy from Nepal's top insurer that suits you before you buy it.
      "
        />
      </Helmet>
      <Navbar />
      <TravelForm />
      {/* <div className="Layout"> */}
      {/* <Banner {...banner} /> */}
      {/* <Definition {...compare} /> */}
      <Offers {...offers} />
      <Works {...works} />
      <FAQs data={faqData} />
      <Contact />
      <Footer />
      {/* </div> */}
    </>
  );
};

export default Travel;
