import React, { useState } from "react";

import Navbar from "Components/Common/Navbar";
import PolicyDetails from "../Forms/PolicyDetails";
import { useFormik } from "formik";
import * as Yup from "yup";
import PersonalDetails from "../Forms/PersonalDetails";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { postWholeLifePolicyQuote } from "slices/policy/thunk";
import { AppDispatch } from "store";
const WholeLifeInsuranceForm = () => {
  const [activeTab, setActiveTab] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      sumAssured: 0,
      term: 0,
      dateOfBirth: [],
      name: "",
      phoneNumber: "",
      email: "",
      policyType: "WholeLife",
    },
    validationSchema: Yup.object({
      sumAssured: Yup.string().required("Please select sum assured"),
      term: Yup.number().required("Please select a term"),
      dateOfBirth: Yup.number().required("Please select parent's date of birth"),
      name: Yup.string().required("Please enter your name"),
      phoneNumber: Yup.string().required("Phone number is required"),
      email: Yup.string().required("Email is required"),
    }),
    onSubmit: (values) => {
      dispatch(postWholeLifePolicyQuote(values));
      localStorage.setItem("wholeLife", JSON.stringify(values));
      navigate("/whole-life-policy");
    },
  });

  return (
    <React.Fragment>
      {/* <Navbar /> */}
      <div className="Layout">
        {activeTab === 1 && (
          <PolicyDetails
            type="Create a whole life insurance quote"
            validation={validation}
            setTab={(item: number) => setActiveTab(item)}
            min={16}
            max={65}
            minTerm={5}
            maxTerm={54}
          />
        )}
        {activeTab === 2 && (
          <PersonalDetails
            validation={validation}
            setTab={(item: number) => setActiveTab(item)}
            steps={2}
            type="Create a whole life insurance quote"
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default WholeLifeInsuranceForm;
