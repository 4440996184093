import React from 'react'
import ProductsCard from './ProductsCard'

const Products = () => {
    const product = ["1", '2', '3', '4', '5']
    return (
        <div className='product'>
            <div className="product_banner">

            </div>
            <div className="product_section">


                <div className="product_section_title">
                    Our Products
                </div>
                <div className="product_section_desc">All Insurance Services Available </div>

                <div className="product_section_list">
                    {product.map((item, index) => {
                        return (
                            <ProductsCard key={index} />
                        )
                    })}
                    <div className='productcard'>
                        <img src="https://placehold.co/376x309" alt="" className="productcard_image" />
                        <div className="productcard_viewall">
                            <button className="productcard_viewall_header">
                                View all
                            </button>
                            <div className="productcard_viewall_desc">
                                Explore all the products available.                            
                                </div>
                        </div>

                    </div>
                </div>
            </div>



        </div>
    )
}

export default Products
