import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFourWheelerPolicyApi } from "helpers/backend_helper";

export const getFourWheelerPolicy = createAsyncThunk("policy/getFourWheelerPolicy", async () => {
    try {
        const response = getFourWheelerPolicyApi()
        return response;
    } catch (error) {
        return error;
    }
});