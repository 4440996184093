import { createSlice } from "@reduxjs/toolkit";
import { getFourWheelerPolicy } from "./thunk";

export interface FourWheelerInsurance {
  company: string;
  planName: "Comprehensive" | "Third-party";
  stampCost: number;
  vatPercent: number;
  perSeatCost: number;
  companyLogo: string;
  prices: {
    minCC: number;
    maxCC: number;
    thirdPartyAdd?: number;
    costPercentBelowTwentyLakhs: number;
    costPercentAboveTwentyLakhs: number;
    reduceAmount: number;
    vechileAgeBands: {
      minAge: number;
      maxAge: number;
      percentAdd: number;
    }[];
  }[];
}
export interface FourWheelerInsuranceReducer {
  status: "success" | "pending" | "error";
  policies: FourWheelerInsurance[];
  cc: number;
  planType: String;
  noOfPassenger: number;
  manufacturedYear: number;
  vechileCost: number;
  riotStrikeDamage: Boolean;
}

export const initialState: FourWheelerInsuranceReducer = {
  status: "pending",
  policies: [],
  cc: 0,
  planType: "",
  noOfPassenger: 0,
  manufacturedYear: 0,
  vechileCost: 0,
  riotStrikeDamage: false,
};

const fourWheelerSlice = createSlice({
  name: "fourWheelerPlan",
  initialState,
  reducers: {
    setFourWheelerPolicyDetails: (state, action) => {
      state.cc = action.payload.cc;
      state.planType = action.payload.planType;
      state.noOfPassenger = action.payload.numberOfPassenger;
      state.manufacturedYear = action.payload.manufacturedYear;
      state.vechileCost = action.payload.vehicleCost;
      state.riotStrikeDamage = action.payload.riotStrikeDamage;
    },
    setFourWheelerPolicyInitial: (state) => {
      state.cc = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFourWheelerPolicy.fulfilled, (state, action) => {
      state.status = "success";
      state.policies = (
        action.payload as { data: FourWheelerInsurance[] }
      ).data;
    });
  },
});

export const { setFourWheelerPolicyDetails, setFourWheelerPolicyInitial } =
  fourWheelerSlice.actions;

export default fourWheelerSlice.reducer;
