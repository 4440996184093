import React from 'react'

const Bannerprotect = () => {
    return (
        <div className="bannerprotectcontainer">
            <div className='bannerprotect'>
                <div className="bannerprotect_content">
                    Protect You and your Loved Ones with Beemalaya
                </div>
                <div className="planbutton">
                    <button>
                        Get Started {">"}
                    </button>
                </div>

            </div>
        </div>

    )
}

export default Bannerprotect
