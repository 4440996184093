import { createAsyncThunk } from "@reduxjs/toolkit";
import { getWholeLifePolicyApi } from "helpers/backend_helper";

export const getWholeLifePolicy = createAsyncThunk(
  "wholeLife/getWholeLifePolicy",
  async () => {
    try {
      const response = await getWholeLifePolicyApi();
      
      
      return response;
    } catch (error) {
      return error;
    }
  }
);
