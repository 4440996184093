import { BADNAME } from "dns";
import { ChildrenLifePolicy } from "slices/childrenPlan/reducer";
import {
  FourWheelerInsurancePlan,
  LifeInsurancePlan,
  TravelInsurancePlan,
  TwoWheelerInsurance,
} from "slices/policy/reducer";
export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function calculateAgeFromDate(dateString: string) {
  // Parse the date of birth string to a Date object
  const dob = new Date(dateString);

  // Get the current date
  const currentDate = new Date();

  const age = currentDate.getFullYear() - dob.getFullYear();

  // Adjust the age if the birthday hasn't occurred yet this year
  if (
    currentDate.getMonth() < dob.getMonth() ||
    (currentDate.getMonth() === dob.getMonth() &&
      currentDate.getDate() < dob.getDate())
  ) {
    return age - 1;
  } else {
    return age;
  }
}

export function calculateDayDifference(
  startDate: string,
  endDate: string
): number {
  const date1 = new Date(startDate);
  const date2 = new Date(endDate);
  const timeDifference = date2.getTime() - date1.getTime();
  const dayDifference = timeDifference / (1000 * 3600 * 24);
  return dayDifference;
}

//Get price for travel insurance plan
export const getPriceByAgeAndDays = (
  insurancePlan: TravelInsurancePlan,
  age: number,
  days: number
): number | null => {
  const price = insurancePlan.prices.find((p) => {
    const isAgeInRange = p.ageBands.some(
      (band) => age >= band.minAge && age <= band.maxAge
    );
    const isDaysInRange = days >= p.minDays && days <= p.maxDays;
    return isAgeInRange && isDaysInRange;
  });
  return price ? price.ageBands[0].price : null;
};

export const getPriceByAgeAndCC = (
  insurancePlan: TwoWheelerInsurance,
  age: number,
  cc: number
): any => {
  const price = insurancePlan.prices.find((p) => {
    const isAgeInRange = p.bikeAgeBands.some(
      (band) => age >= band.minAge && age <= band.maxAge
    );
    const isCCInRange = cc >= p.minCC && cc <= p.maxCC;
    return isAgeInRange && isCCInRange;
  });
  // const bikeAgeBands = p.bikeAgeBands.((band))
  return { price };
};

export const getPriceByCC = (
  insurancePlan: TwoWheelerInsurance,
  cc: number
): any => {
  const price = insurancePlan.prices.find((p) => {
    const isCCInRange = cc >= p.minCC && cc <= p.maxCC;
    return isCCInRange;
  });
  // const bikeAgeBands = p.bikeAgeBands.((band))
  return { price };
};
export const getPriceByCCForVehicle = (
  insurancePlan: FourWheelerInsurancePlan,
  cc: number
): any => {
  const price = insurancePlan.prices.find((p) => {
    const isCCInRange = cc >= p.minCC && cc <= p.maxCC;
    return isCCInRange;
  });
  return { price };
};
export const getPriceByAgeAndCCForVehicle = (
  insurancePlan: FourWheelerInsurancePlan,
  age: number,
  cc: number
): any => {
  const price = insurancePlan.prices.find((p) => {
    const isAgeInRange = p.vechileAgeBands.some(
      (band) => age >= band.minAge && age <= band.maxAge
    );
    const isCCInRange = cc >= p.minCC && cc <= p.maxCC;
    return isAgeInRange && isCCInRange;
  });
  return { price };
};

export const calculateNetPremiumForVechile = (
  vehicleCost: number,
  percent1: any,
  percent2: any
) => {
  const baseAmount = Math.min(vehicleCost, 2000000);
  const excessAmount = Math.max(vehicleCost - 2000000, 0);

  const cost = (percent1 * baseAmount + percent2 * excessAmount) / 100;

  return cost;
};

export const getPriceByAgeAndTermForLife = (
  insurancePlan: LifeInsurancePlan | ChildrenLifePolicy,
  age: number,
  term: number
): any => {
  const price = insurancePlan.prices.find((p) => {
    if (typeof term === "string") {
      term = parseInt(term);
    }
    const isTerm = p.termPeriod.some((band) => term === band.totalYears);
    const isAge = p.age === age;

    return isTerm && isAge;
  });

  return { price };
};
