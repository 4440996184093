import React from "react";
import hearlogo from "../../assets/testimonial/hear.svg";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';

const Testimonials = () => {
  const testimonialdata = [
    {
      id: 1,
      desc: "Just a few clicks and I found the best insurance policy that suits me.",
      name: "Sam Cheetri",
      position: "Accountant"


    },
    {
      id: 2,
      desc: "I had a really great 30-minute consultation with Beemalaya. They were super professional and helped me understand the policies of all the insurance companies in Nepal.",
      name: "Nishant Sharma",
      position: "Senior Manager"

    }, {
      id: 3,
      desc:"They made it really easy for me to renew my motorcycle's third-party insurance policy. While I was at work, they picked up the necessary documents and delivered them back to me once the renewal was done.",
      name: "Hari Chandra Shekhar",
      position: "Software Engineers"

    }
  ]


  return (
    <div className="testimonials">
      <div className="testimonials_content">
        <div className="testimonials_content_header">
          <div className="happy">Happy customers</div>
          <div className="hear">Hear what our customer have to say</div>
        </div>
        <div className="testimonials_content_desc">


          <Swiper
            cssMode={true}
            navigation={true}
            pagination={true}
            mousewheel={true}
            keyboard={true}
            modules={[Navigation, Mousewheel, Keyboard]}
            injectStyles={ [
              `
                .swiper-button-next,
                .swiper-button-prev {
                  background-color: white;
                  padding: 8px 16px;
                  border-radius: 100%;
                  border: 2px solid black;
                  color: red;
                }
                .swiper-pagination-bullet{
                  width: 40px;
                  height: 40px;
                  background-color: red;
                }
                .swiper-button-next:before,
                .swiper-button-prev:after {
                  font-size: 10px;
                }
            `]}
            className="mySwiper"
          >
            {testimonialdata.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="testimonial">
                    <div className="testimonial_logo">
                      <img src={hearlogo} alt="" />
                    </div>
                    <div className="testimonial_desc">
                      {item.desc}
                    </div>
                    <div className="testimonial_profile">
                      <div className=""></div>

                      <div className="testimonial_profile_info">
                        <div className="name">{item.name}</div>
                        <div className="position">{item.position}</div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              )})}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
