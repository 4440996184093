import { createAsyncThunk } from "@reduxjs/toolkit";
import { getChildrenPolicyApi } from "helpers/backend_helper";

export const getChildrenPolicy = createAsyncThunk("childrenPlan/getChildrenPolicy", async () => {
    try {
        const response =await getChildrenPolicyApi();
    
        return response;
    } catch (error) {
        return error;
    }
});
