import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTravelPolicyApi } from "helpers/backend_helper";

export const getTravelPolicy = createAsyncThunk("travelPlan/getTravelPolicy", async (data:any, { dispatch }) => {
    try {
        const {planName, destinationType} = data
        const planNameEncoded = encodeURIComponent(planName);
        const destinationTypeEncoded = encodeURIComponent(destinationType);
        const response = await getTravelPolicyApi(planNameEncoded, destinationTypeEncoded)
        return response;
    } catch (error) {
        return error;
    }
});