import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import config from "../config";
const { api } = config;

// default
axios.defaults.baseURL = api.API_URL;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// content type
const token: any = sessionStorage.getItem('token')

if (token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    if(response.data.status === 0){
      let message = {data: response.data.message } 
      
      return Promise.reject(message);
    }
    return response.data ? response.data : response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error.response.status) {
      case 500:
        message = {data: "Internal Server Error"};
        break;
      case 403:
        message ={data: "Invalid credentials"};
        break;
      case 404:
        message = {data: "Sorry! the data you are looking for could not be found"};
        break;
      default:
        
        if(error.response.data.message === 'Validation Error.'){
          
          message= {data: error.response.data.data[0].msg}

        } else{
          message = {data: error.response.data.message || error};
        }
        
    }
    return Promise.reject(message);
  }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token : string) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from the given URL
   */
  get = (url: string, params?: any): Promise<AxiosResponse> => {
    let response: Promise<AxiosResponse>;

    let paramKeys: string[] = [];

    if (params) {
      Object.keys(params).map(key => {
        paramKeys.push(key + '=' + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };

  /**
   * Posts the given data to the URL
   */
  create = (url: string, data?: any): Promise<AxiosResponse> => {
    if(data){
      return axios.post(url, data);
    } else {
      return axios.post(url);
    }
  };

  /**
   * Updates data
   */
  update = (url: string, data: any): Promise<AxiosResponse> => {
    return axios.patch(url, data);
  };

  put = (url: string, data: any): Promise<AxiosResponse> => {
    return axios.put(url, data);
  };

  /**
   * Deletes data
   */
  delete = (url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> => {
    return axios.delete(url, { ...config });
  };
}

const getLoggedinUser = () => {
  const user = sessionStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};
const getTokenForUser = () => {
  const token = sessionStorage.getItem("token");
  if (!token) {
    return null;
  } else {
    return token;
  }
}

export { APIClient, setAuthorization, getLoggedinUser, getTokenForUser };