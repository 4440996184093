import React from "react";
import logo1 from "../../assets/whychooseus/logo1.svg";
import logo2 from "../../assets/whychooseus/logo2.svg";
import logo3 from "../../assets/whychooseus/logo3.svg";

const Whychooseus = () => {
  const Reason = ({ title }) => {
    return (
      <div className="reason">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <g clip-path="url(#clip0_416_2932)">
            <path
              d="M12.5869 2C7.06691 2 2.58691 6.48 2.58691 12C2.58691 17.52 7.06691 22 12.5869 22C18.1069 22 22.5869 17.52 22.5869 12C22.5869 6.48 18.1069 2 12.5869 2ZM10.5869 17L5.58691 12L6.99691 10.59L10.5869 14.17L18.1769 6.58L19.5869 8L10.5869 17Z"
              fill="#2CAB93"
            />
          </g>
          <defs>
            <clipPath id="clip0_416_2932">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="translate(0.586914)"
              />
            </clipPath>
          </defs>
        </svg>

        <div className="reason_title">{title}</div>
      </div>
    );
  };
  return (
    <div className="whycontainer">
      <div className="why">
        <div className="why_header">
          <div className="why_header_title">
            Why you should choose <span>Beemalaya</span>
          </div>
          <div className="why_header_desc">
            Find the best insurance policy that suits you before you buys it.
          </div>
        </div>

        <div className="why_content">
          <div className="why_content_logo">
            <div className="logo1">
              <img
                src="https://utfs.io/f/8233b8ae-765e-453e-a1b5-8d3a9c5cd39e-1peup2.svg"
                alt=""
              />
              <div>Your One Stop Solution</div>
            </div>
            <div className="logo2">
              <img
                src="https://utfs.io/f/eb0afd24-e22a-45cc-a311-7f6109dd88bd-1peup3.svg"
                alt=""
              />
            </div>
            <div className="logo3">
              <img
                src="https://utfs.io/f/27dba461-6204-4eb6-ad62-ef7c6be10827-1peup4.svg"
                alt=""
              />
            </div>
            <div className="logo4"></div>
          </div>
          <div className="why_content_reason">
            <div className="reasoncontainer">
              <Reason title="Large number of insurance policies" />
              <Reason title="Unbiased comparison" />
              <Reason title="Customized insurance quotes" />
            </div>
            <div className="why_content_reason_desc">
              Our loved ones should be insured with the best insurance policy
              available in the market. Stay Safe, Stay Insured with beemalaya.
            </div>
            <div className="why_content_reason_flex">
              Best insurance policy at your hand.
            </div>

            <button className="discover_button">Discover More</button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Whychooseus;
