import Navbar from "Components/Common/Navbar";
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import { RootState } from "slices";
import { useSelector } from "react-redux";
import FourWheelerPolicyCard from "./FourWheelerPolicyCard";
import { getTwoWheelerPolicy } from "slices/twoWheeler/thunk";
import { setTwoWheelerPolicyDetails, TwoWheelerInsuranceReducer } from "slices/twoWheeler/reducer";
import { getFourWheelerPolicy } from "slices/fourWheeler/thunk";
import { FourWheelerInsuranceReducer, setFourWheelerPolicyDetails } from "slices/fourWheeler/reducer";
// import SumAssured from "pages/Life/Forms/SumAssured";

const FourWheelerPolicy = () => {
  document.title = "Beemalaya | Four Wheeler Policy ";
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    const data: any = localStorage.getItem("fourWheeler");
    const fourWheelerData = JSON.parse(data);
    dispatch(
      setFourWheelerPolicyDetails({
        cc: fourWheelerData?.capacity || 0,
        planType: fourWheelerData?.planType,
        numberOfPassenger: fourWheelerData?.numberOfPassenger,
        manufacturedYear: fourWheelerData?.manufacturedYear,
        vehicleCost: fourWheelerData?.vehicleCost,
        riotStrikeDamage: fourWheelerData?.riotStrikeDamage
      })
    );
    dispatch(getFourWheelerPolicy());
  }, [dispatch]);
  const PolicyState: FourWheelerInsuranceReducer = useSelector(
    (state: RootState) => state.FourWheeler
  );
  const { policies, status } = PolicyState;


  return (
    <>
      <Navbar />
      <div className="Layout">
        <div className="plan">
          {/* <div className="plan_filter">
            <div className="plan_filter_user">
              <span>john Doe</span>
              <span>25years</span>
              <span>Malbe</span>
            </div>

            <div className="plan_filter_sumassured">
              <div>Sum assured:</div>
              <input type="number" />
            </div>

            <div className="plan_filter_filter_term">
              <div>Term:</div>
              <select name="term" className="selectterm">
                <option value="" disabled hidden>
                  Years
                </option>
                <option value="1 years ">1 years</option>
                <option value="2 years ">2 years </option>
                <option value="3 years ">3 years </option>
                <option value="4 years">4 years</option>
              </select>
            </div>
            <div className="plan_filter_filter_term">
              <div>Age:</div>
              <select name="age" className="selectage">
                <option value="" disabled hidden>
                  Years
                </option>
                <option value="1 years ">1 years</option>
                <option value="2 years ">2 years </option>
                <option value="3 years ">3 years </option>
                <option value="4 years">4 years</option>
              </select>
            </div>

            <button className="button-primary-2">Filter</button>
          </div> */}
          <div className="plan_content">
            <div className="plan_content_searchresult">
              {" "}
              Result: {policies?.length} plans match your search
            </div>
            <div className="cardcontainer">
              {status === "success" &&
                policies.map((policy, index: number) => {
                  return (
                    <>
                      <div className="cardcontainer_card">
                        <FourWheelerPolicyCard policy={policy} />{" "}
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FourWheelerPolicy;
