import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFourWheelerPolicyApi, getLifeInsurancePolicyApi, getTravelPolicyApi, getTwoWheelerPolicyApi, postChildrenPolicyApi, postEndowmentLifePolicyApi, postTermLifePolicyApi, postTravelPolicyApi, postTwoWheelerPolicyApi, postWholeLifePolicyApi } from "helpers/backend_helper";
export const postTravelQuote = createAsyncThunk("policy/postTravelQuote", async (data:any, { dispatch }) => {
    try {
      
        const response = await postTravelPolicyApi(data)
        return response;
    } catch (error) {
        return error;
    }
});
export const postTwoWheelerQuote = createAsyncThunk("policy/postTravelQuote", async (data:any, { dispatch }) => {
    try {
      
        const response = await postTwoWheelerPolicyApi(data)
        return response;
    } catch (error) {
        return error;
    }
});
export const postEndowmentPolicyQuote = createAsyncThunk("policy/postTravelQuote", async (data:any, { dispatch }) => {
    try {
      
        const response = await postEndowmentLifePolicyApi(data)
        return response;
    } catch (error) {
        return error;
    }
});
export const postTermPolicyQuote = createAsyncThunk("policy/postTravelQuote", async (data:any, { dispatch }) => {
    try {
      
        const response = await postTermLifePolicyApi(data)
        return response;
    } catch (error) {
        return error;
    }
});
export const postWholeLifePolicyQuote = createAsyncThunk("policy/postTravelQuote", async (data:any, { dispatch }) => {
    try {
      
        const response = await postWholeLifePolicyApi(data)
        return response;
    } catch (error) {
        return error;
    }
});
export const postChildrenPolicyQuote = createAsyncThunk("policy/postTravelQuote", async (data:any, { dispatch }) => {
    try {
      
        const response = await postChildrenPolicyApi(data)
        return response;
    } catch (error) {
        return error;
    }
});
export const getTravelPolicy = createAsyncThunk("policy/getTravelPolicy", async (data:any, { dispatch }) => {
    try {
        const {planName, destinationType} = data
        const planNameEncoded = encodeURIComponent(planName);
        const destinationTypeEncoded = encodeURIComponent(destinationType);
        const response = await getTravelPolicyApi(planNameEncoded, destinationTypeEncoded)
        return response;
    } catch (error) {
        return error;
    }
});

export const getTwoWheelerPolicy = createAsyncThunk("policy/getTwoWheelerPolicy", async () => {
    try {
        const response = getTwoWheelerPolicyApi()
        return response;
    } catch (error) {
        return error;
    }
});

export const getFourWheelerPolicy = createAsyncThunk("policy/getFourWheelerPolicy", async () => {
    try {
        const response = getFourWheelerPolicyApi()
        return response;
    } catch (error) {
        return error;
    }
});

export const getLifeInsurancePolicy = createAsyncThunk("policy/getLifeInsurancePolicy", async () => {
    try {
        const response = getLifeInsurancePolicyApi()
        return response;
    } catch (error) {
        return error;
    }
});
