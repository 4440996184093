import React from "react";
interface OfferItem {
  image: any;
  text: String;
}
export interface OfferInterface {
  offerData: OfferItem[];
  policyName: String;
}
const OfferSingle: React.FC<OfferItem> = (data) => {
  return (
    <div className="OfferSingle">
      <img src={data.image} alt="Offer_Single_Image" />
      <p>{data.text}</p>
    </div>
  );
};
const Offers: React.FC<OfferInterface> = (data) => {
  return (
    <div className="Offers">
      <div className="Offers_Content">
        <h1>
          What a <span>{data.policyName}</span> Offers?
        </h1>
        <p>
          Whether you’re just starting out on your own or thinking about
          protecting your family, we’ll help you find a balance between
          financial protection and financial growth.
        </p>
        <div className="Offers_Data">
          {data.offerData?.map((dat) => (
            <OfferSingle {...dat} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Offers;
