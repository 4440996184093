import React from "react";
import AboutImage from "../../assets/About.jpg";
const Banner = () => {
  return (
    <div className="Banner">
      <div className="Banner_Image1">
        {/* <img
          src={AboutImage}
          alt="Term_Life_Image"
          className="Banner_Image_Img"
        /> */}
      </div>
      <div className="Banner_Content">
        <div className="Banner_Content_Heading">About Us</div>
        <h2>We're here to help you find the best policy available!</h2>
      </div>
    </div>
  );
};

export default Banner;
