import React from "react";

const HowItStarted = () => {
  return (
    <div className="HowItStarted">
    <div className="HowItStarted_Content">
        <h1>How Beemalaya Started?</h1>
        <p>
          Beemalaya was started with a simple mission to make insurance sector
          transparent. We help customer to make wise decision while buying
          insurance policy.
        </p>
      </div>
    </div>
  );
};

export default HowItStarted;
