import Navbar from "Components/Common/Navbar";
import React, { useEffect, useState } from "react";
import PolicyCard from "../PolicyCard";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import { RootState } from "slices";
import { useSelector } from "react-redux";
import { getEndowmentLifePolicy } from "slices/endowmentPolicy/thunk";
import {
  EndowmentLifeInsuranceReducer,
  setEndowmentPolicyDetails,
} from "slices/endowmentPolicy/reducer";
import { calculateAgeFromDate } from "helpers/function_helper";
import EndowmentPolicyCard from "./EndowmentPolicyCard";
import Keyboard from "assets/keyboard.svg";
// import SumAssured from "pages/Life/Forms/SumAssured";
import PlanSidebar from "Components/Common/planSidebar";

const EndowmentPolicy = () => {
  document.title = "Beemalaya | Endowment Policy";
  const [show, setShow] = useState<number[]>([]);
  const [sidebarvisible, setSidebarVisible] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const [sidebardata, setSidebardata] = useState<any>({});
  useEffect(() => {
    const data: any = localStorage.getItem("endowment");
    const endowmentData = JSON.parse(data);
    // const age = calculateAgeFromDate(endowmentData.dateOfBirth);
    const age = parseInt(endowmentData.dateOfBirth);
    dispatch(
      setEndowmentPolicyDetails({
        sumAssured: endowmentData.sumAssured,
        term: parseInt(endowmentData.term),
        age: age,
      })
    );
    dispatch(getEndowmentLifePolicy());
  }, [dispatch]);
  const PolicyState: EndowmentLifeInsuranceReducer = useSelector(
    (state: RootState) => state.Endowment
  );
  const { policies, policyModified, status } = PolicyState;

  return (
    <>
      <Navbar />
      <div
        className="Layout"
        style={{
          filter: sidebarvisible ? "blur(2px)" : "none",
        }}
        id="layoutplan"
      >
        <div className="plan">
          <div className="plan_content">
            <div className="plan_content_searchresult">
              {" "}
              Result: {policyModified?.length} plans match your search
            </div>
            <div className="cardcontainer">
              {status === "success" &&
                policyModified.map((policy, index: number) => {
                  return (
                    <>
                      <EndowmentPolicyCard
                        policy={policy[0]}
                        setSidebarVisible={setSidebarVisible}
                        sidebardata={sidebardata}
                        setSidebardata={setSidebardata}
                      />{" "}
                      {show.includes(index) &&
                        policy.map(
                          (dat, index) =>
                            index !== 0 && (
                              <EndowmentPolicyCard
                                policy={dat}
                                setSidebarVisible={setSidebarVisible}
                                sidebardata={sidebardata}
                                setSidebardata={setSidebardata}
                              />
                            )
                        )}
                      {policy.length > 1 && show.includes(index) ? (
                        <div
                          className="cardcontainer_card_moreplan"
                          onClick={() => {
                            let shown = [...show];
                            const subIndex = shown.indexOf(index);
                            if (subIndex > -1) {
                              shown.splice(subIndex, 1);
                            }
                            setShow(shown);
                            // show = true;
                          }}
                        >
                          view less plan
                          <img src={Keyboard} alt="Key board" />
                        </div>
                      ) : (
                        <>
                          {policy.length > 1 && (
                            <div
                              className="cardcontainer_card_moreplan"
                              onClick={() => {
                                let shown = [...show];
                                shown.push(index);
                                setShow(shown);
                                // show = true;
                              }}
                            >
                              view more plan
                              <img src={Keyboard} alt="Key board" />
                            </div>
                          )}
                        </>
                      )}
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      {sidebarvisible && (
        <PlanSidebar
          setSidebarVisible={setSidebarVisible}
          sidebardata={sidebardata}
        />
      )}
    </>
  );
};

export default EndowmentPolicy;
