import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import SumAssured from "./SumAssured";
interface PersonalDetailsInterface {
  validation: any;
  setTab: any;
  steps: number;
  type: String;
}
const PersonalDetails: React.FC<PersonalDetailsInterface> = ({
  validation,
  setTab,
  steps,
  type
}) => {
  const validation1: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "",
      phoneNumber: "",
      email: "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Please enter your name"),
      phoneNumber: Yup.string()
      .required('Phone number is required')
      .matches(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, 'Invalid phone number format'),
    email: Yup.string()
      .required('Email is required')
      .email('Invalid email format'),
    }),
    onSubmit: (values) => {
      
    validation.handleSubmit();
    },
  });
  return (
    <div className="Form">
      <div className="Form_Heading">
        <h1>{type}</h1>
      </div>
      <div className="Form_Body">
        <div className="Form_Body_Heading">
          <p>Personal Details</p>
          <div className="Form_Body_Heading_Container">
            <div
              className="Form_Body_Heading_Progress"
              style={{ width: "100%" }}
            ></div>
          </div>
          <span>
            Step {steps} of {steps}
          </span>
        </div>
        <div className="Form_Body_Body">
          <div className="Form_Body_Body_InputBox">
            <p>Name *</p>
            <input
              className={
                validation1.touched.name && validation1.errors.name
                  ? "Form_Body_Body_InputDanger"
                  : "Form_Body_Body_Input"
              }
              type="text"
              placeholder="Enter your name"
              onChange={(e) => {
                validation.setFieldValue("name", e.target.value);
                validation1.setFieldValue("name", e.target.value);
              }}
            />
            {validation1.touched.name && validation1.errors.name && (
              <div className="text-danger">{validation1.errors.name}</div>
            )}
          </div>
          <div className="Form_Body_Body_InputBox">
            <p>Phone Number *</p>
            <input
              className={
                validation1.touched.phoneNumber && validation1.errors.phoneNumber
                  ? "Form_Body_Body_InputDanger"
                  : "Form_Body_Body_Input"
              }
              type="number"
              placeholder="Enter your phone number"
              onChange={(e) => {
                validation.setFieldValue("phoneNumber", e.target.value);
                validation1.setFieldValue("phoneNumber", e.target.value);
              }}
            />
             {validation1.touched.phoneNumber && validation1.errors.phoneNumber && (
              <div className="text-danger">{validation1.errors.phoneNumber}</div>
            )}
          </div>
          <div className="Form_Body_Body_InputBox">
            <p>Email *</p>
            <input
              className={
                validation1.touched.email && validation1.errors.email
                  ? "Form_Body_Body_InputDanger"
                  : "Form_Body_Body_Input"
              }
              type="email"
              placeholder="Enter your email address"
              onChange={(e) => {
                validation.setFieldValue("email", e.target.value);
                validation1.setFieldValue("email", e.target.value);
              }}
            />
            {validation1.touched.email && validation1.errors.email && (
              <div className="text-danger">{validation1.errors.email}</div>
            )}
          </div>
        </div>
        <div className="Form_Body_Button">
          <button
            className="contactsubmit1"
            onClick={() => {
              setTab(1);
            }}
          >
            Back
          </button>
          <button
            type="submit"
            className="contactsubmit1"
            onClick={() => {
              validation1.handleSubmit();
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;
