import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

interface CoveragePlanInterface {
  validation: any;
  setTab: any;
}
const CoveragePlan: React.FC<CoveragePlanInterface> = ({
  validation,
  setTab,
}) => {
  const validation1: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      coverageType: "",
      destinationType: "",
      planName: "",
      startDate: "",
      endDate: "",
      dateOfBirth: "",
    },

    validationSchema: Yup.object({
      coverageType: Yup.string().required("Please Choose a Plan"),
      planName: Yup.string().required("Please select plan name"),
      destinationType: Yup.string().required(
        "Please select place of destination"
      ),
      startDate: Yup.string().required("Please select start date"),
      endDate: Yup.string().required("Please select end date"),
      dateOfBirth: Yup.string().required("Please select date of birth"),
    }),
    onSubmit: (values) => {
      setTab(2);
    },
  });
  return (
    <div className="Form">
      <div className="Form_Heading">
        <h1>Create a Travel Insurance Quote</h1>
      </div>
      <div className="Form_Body">
        <div className="Form_Body_Heading">
          <p>Choose your plan</p>
          <div className="Form_Body_Heading_Container">
            <div
              className="Form_Body_Heading_Progress"
              style={{ width: "50%" }}
            ></div>
          </div>
          <span>Step 1 of 2</span>
        </div>
        <div className="Form_Body_Body">
          <button
            className={
              validation.values.coverageType === "Individual"
                ? "Form_Body_Body_ButtonPrimary1"
                : "Form_Body_Body_Button1"
            }
            onClick={() => {
              validation1.setFieldValue("coverageType", "Individual");
              validation.setFieldValue("coverageType", "Individual");
            }}
          >
            <input
              type="radio"
              className="Form_Body_Body_Button_Radio"
              checked={validation.values.planType === "Individual"}
            />
            <div className="Form_Body_Body_Button_Right">
              <p>Individual</p>
              {/* <p>Cover damages to sell as well as third party.</p> */}
            </div>
          </button>
          <div className="Form_Body_Body_InputBox">
            <p>Plan Name *</p>
            <select
               className={
                validation1.touched.planName && validation1.errors.planName
                  ? "Form_Body_Body_InputDanger"
                  : "Form_Body_Body_Input"
              }
              id="planName"
              name="planName"
              value={validation.values.planName}
              onChange={(e) => {
                validation.setFieldValue("planName", e.target.value);
                validation1.setFieldValue("planName", e.target.value);
              }}
              onBlur={validation.handleBlur}
            >
              <option defaultValue="">Choose plan</option>
              <option value="Medical only - A to C">
                Travel Medical Insurance (HTL)(A-C)
              </option>
              <option value="Package policy - A to N">
                Travel Medical Insurance Comprehensive__(A-N)
              </option>
            </select>
            {validation1.touched.planName && validation1.errors.planName && (
              <div className="text-danger">{validation1.errors.planName}</div>
            )}
          </div>
          <div className="Form_Body_Body_InputBox">
            <p>Destination Type *</p>

            <select
                className={
                  validation1.touched.destinationType && validation1.errors.destinationType
                    ? "Form_Body_Body_InputDanger"
                    : "Form_Body_Body_Input"
                }
              id="destinationType"
              name="destinationType"
              value={validation.values.destinationType}
              onChange={(e) => {
                validation.setFieldValue("destinationType", e.target.value);
                validation1.setFieldValue("destinationType", e.target.value);
              }}
              onBlur={validation.handleBlur}
            >
              <option defaultValue="">Choose place of travel</option>
              <option value="Worldwide including USA & Canada">
                Worldwide including USA & Canada
              </option>
              <option value="Worldwide excluding USA & Canada">
                Worldwide excluding USA & Canada
              </option>
              <option value="Asian countries">Asian countries</option>
              <option value="SAARC Countries">SAARC Countries</option>
            </select>
            {validation1.touched.destinationType &&
              validation1.errors.destinationType && (
                <div className="text-danger">
                  {validation1.errors.destinationType}
                </div>
              )}
          </div>
          <div className="Form_Body_Body_InputBox">
            <p>Date of Travel *</p>
            <input
                className={
                  validation1.touched.startDate && validation1.errors.startDate
                    ? "Form_Body_Body_InputDanger"
                    : "Form_Body_Body_Input"
                }
              type="date"
              placeholder="Travel From"
              onChange={(e) => {
                validation.setFieldValue("startDate", e.target.value);
                validation1.setFieldValue("startDate", e.target.value);
              }}
            />
            {validation1.touched.startDate && validation1.errors.startDate && (
              <div className="text-danger">{validation1.errors.startDate}</div>
            )}
          </div>
          <div className="Form_Body_Body_InputBox">
            <p>End of travel *</p>
            <input
                className={
                  validation1.touched.endDate && validation1.errors.endDate
                    ? "Form_Body_Body_InputDanger"
                    : "Form_Body_Body_Input"
                }
              type="date"
              placeholder="Travel To"
              onChange={(e) => {
                validation.setFieldValue("endDate", e.target.value);
                validation1.setFieldValue("endDate", e.target.value);
              }}
            />
            {validation1.touched.endDate && validation1.errors.endDate && (
              <div className="text-danger">{validation1.errors.endDate}</div>
            )}
          </div>
          <div className="Form_Body_Body_InputBox">
            <p>Date of Birth *</p>
            <input
              className={
                validation1.touched.dateOfBirth && validation1.errors.dateOfBirth
                  ? "Form_Body_Body_InputDanger"
                  : "Form_Body_Body_Input"
              }
              type="date"
              placeholder="Age of Traveller"
              onChange={(e) => {
                validation.setFieldValue("dateOfBirth", e.target.value);
                validation1.setFieldValue("dateOfBirth", e.target.value);
              }}
            />
            {validation1.touched.dateOfBirth &&
              validation1.errors.dateOfBirth && (
                <div className="text-danger">
                  {validation1.errors.dateOfBirth}
                </div>
              )}
          </div>
          {/* <input
            className="Form_Body_Body_Input"
            type="number"
            placeholder="Enter your phone number"
            onChange={(e) => {
              validation.setFieldValue("phoneNumber", e.target.value);
              validation1.setFieldValue("phoneNumber", e.target.value);
            }}
          /> */}
        </div>
        <div className="Form_Body_Button">
          <div></div>
          <button
            type="submit"
            className="contactsubmit1"
            onClick={() => {
              validation1.handleSubmit();
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default CoveragePlan;
