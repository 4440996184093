import { APIClient } from "./api_helper";

import * as url from "./url_helper";

const api = new APIClient();

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

// //is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// postForgetPwd
export const postFakeForgetPwd = (data: any) =>
  api.create(url.POST_FAKE_PASSWORD_FORGET, data);

// Register Method
// export const postJwtRegister = (url : string, data  :any) => {
//   return api.create(url, data)
//     .catch(err => {
//       var message;
//       if (err.response && err.response.status) {
//         switch (err.response.status) {
//           case 404:
//             message = "Sorry! the page you are looking for could not be found";
//             break;
//           case 500:
//             message = "Sorry! something went wrong, please contact our support team";
//             break;
//           case 401:
//             message = "Invalid credentials";
//             break;
//           default:
//             message = err[1];
//             break;
//         }
//       }
//       throw message;
//     });
// };
// Authentication
// Login Method
export const postJwtLogin = (data: any) => api.create(url.POST_JWT_LOGIN, data);
export const postLogout = (sessionId: any) =>
  api.create(`${url.POST_LOGOUT}?sid=${sessionId}`);
// Register Method
export const postJwtRegister = (data: any) =>
  api.create(url.POST_JWT_REGISTER, data);
export const postVerifyOtp = (data: any) =>
  api.create(url.POST_VERIFY_OTP, data);
export const postResendOtp = (data: any) =>
  api.create(url.POST_RESEND_OTP, data);
//User Company Details

// postForgetPwd
export const postJwtForgetPwd = (data: any) =>
  api.create(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = (data: any) =>
  api.create(url.SOCIAL_LOGIN, data);

// policies
// travel policies

export const getTravelPolicyApi = (planName: string, destinationType: string) =>
  api.get(
    `${url.GET_TRAVEL_POLICY}?planName=${planName}&&destinationType=${destinationType}`
  );
export const postTravelPolicyApi = (data: any) =>
  api.create(`${url.GET_TRAVEL_POLICY}`, data);
export const getTwoWheelerPolicyApi = () =>
  api.get(`${url.GET_TWO_WHEELER_POLICY}`);
export const postTwoWheelerPolicyApi = (data: any) =>
  api.create(`${url.GET_TWO_WHEELER_POLICY}`, data);
export const getFourWheelerPolicyApi = () =>
  api.get(`${url.GET_FOUR_WHEELER_POLICY}`);
export const getLifeInsurancePolicyApi = () =>
  api.get(`${url.GET_LIFE_INSURANCE_POLICY}`);
export const getBlogsApi = () => api.get(url.GET_BLOG);
export const getEndowmentLifePolicyApi = () =>
  api.get(`${url.GET_ENDOWMENT_INSURANCE_POLICY}`);
export const postEndowmentLifePolicyApi = (data: any) =>
  api.create(`${url.GET_ENDOWMENT_INSURANCE_POLICY}`, data);
export const getTermLifePolicyApi = () =>
  api.get(`${url.TERM_LIFE_INSURANCE_POLICY}`);
export const postTermLifePolicyApi = (data: any) =>
  api.create(`${url.TERM_LIFE_INSURANCE_POLICY}`, data);
export const getChildrenPolicyApi = () =>
  api.get(`${url.CHILDREN_LIFE_POLICY}`);
export const postChildrenPolicyApi = (data: any) =>
  api.create(`${url.CHILDREN_LIFE_POLICY}`, data);
export const getWholeLifePolicyApi = () =>
  api.get(`${url.WHOLE_LIFE_INSURANCE_POLICY}`);

export const postWholeLifePolicyApi = (data: any) =>
  api.create(`${url.WHOLE_LIFE_INSURANCE_POLICY}`, data);
