import React from "react";
import Navbar from "../../Components/Common/Navbar";
import Banner, { BannerDataInterface } from "../layouts/Banner";
import Definition, { CompareInterface } from "testPages/layouts/Definition";
import Offers, { OfferInterface } from "testPages/layouts/Offers";
import CostEffective from "../../assets/costEffective.svg";
import FinancialProtection from "../../assets/financialProtection.svg";
import Flexibility from "../../assets/flexibility.svg";
import Affordability from "../../assets/affordability.svg";
import LifeStage from "../../assets/lifeStage.svg";
import CoverageAmount from "../../assets/CoverageAmount.png";
import ProtectionDuration from "../../assets/ProtectionDuration.png";
import FinancialSecurity from "../../assets/FinancialSecurity.png";
import PeaceOfMind from "../../assets/PeaceOfMind.png";
import Works, { WorkInterface } from "testPages/layouts/Works";
import FAQs, { FAQItem } from "testPages/layouts/FAQs";
import Footer from "Components/landing/Footer";
import Contact from "Components/landing/Contact";
import TermLifeInsuranceForm from "pages/Life/TermLifeInsurance";
import { Helmet } from "react-helmet-async";

interface TermDataInterface {
  banner: BannerDataInterface;
  compare: CompareInterface;
  offers: OfferInterface;
  works: WorkInterface;
  faqData: FAQItem[];
}
const TermData: TermDataInterface = {
  banner: {
    name: "Term Life Insurance",
    link: "/term-life-insurance-quote",
  },
  compare: {
    title: "How to compare Term policy?",
    paragraph: [
      "1. Enter your age.",
      "2. Enter the term you want to be insured for.",
      "3. Enter the sum assured",
      "4. Compare between policies.",
      "5. View details.",
    ],
    name: "Term Life Insurance",
    importance:
      "Term Life Insurance provide financial protection to beneficiaries (usually family members) in the event of the insured person's death during the term of the policy",
  },
  offers: {
    policyName: "Term Life Insurance Policy",
    offerData: [
      {
        image: CostEffective,
        text: "Cost Effectiveness",
      },
      {
        image: FinancialProtection,
        text: "Financial protections to your loved ones",
      },
      {
        image: Affordability,
        text: "Affordability",
      },
      {
        image: Flexibility,
        text: "Flexibility to choose a period",
      },
    ],
  },
  works: {
    policyName: "Term Life Insurance Policy",
    insurance: "Term Life Insurance",
    workData: [
      {
        id: "01",
        image: LifeStage,
        text: "Age 35, facing financial responsibilities like mortgage, children's education, etc.",
        heading: "Life Stage",
      },
      {
        id: "02",
        image: CoverageAmount,
        text: "NRS 500,000 policy ensures substantial support for beneficiaries.",
        heading: "Coverage Amount",
      },
      {
        id: "03",
        image: ProtectionDuration,
        text: "20-year term aligns with family's reliance on income.",
        heading: "Protection Duration",
      },
      {
        id: "04",
        image: FinancialSecurity,
        text: "Payout aids family's financial stability in case of death.",
        heading: "Financial Security",
      },
      {
        id: "05",
        image: PeaceOfMind,
        text: "Family's financial security brings peace, enabling joyful living.",
        heading: "Peace of Mind",
      },
    ],
  },
  faqData: [
    {
      id: 1,
      title: "What is term life insurance?",
      description: [
        "Term life insurance is a type of life insurance policy that provides coverage for a specified period, known as the term. If the insured person were to pass away during the term of the policy, a death benefit would be paid out to the designated beneficiaries.",
        "It offers coverage for a specific duration, such as 10, 20, or 30 years.",
      ],
    },
    {
      id: 2,
      title:
        "How does term life insurance differ from other types of life insurance?",
      description: [
        "Term life insurance differs from other types of life insurance, such as whole life or universal life insurance, in a few ways:",
        "a) Duration: Term life insurance provides coverage for a specific term, while whole life insurance covers the insured's entire life.",
        "b) Premiums: Term life insurance typically has lower premiums compared to whole life insurance.",
        "c) Cash value: Unlike whole life insurance, term life insurance does not accumulate cash value over time.",
      ],
    },
    {
      id: 3,
      title: " What does term life insurance cover?",
      description: [
        "Term life insurance provides a death benefit to the beneficiaries if the insured person passes away during the policy term.",
        "The death benefit can be used by the beneficiaries to cover various financial needs, such as paying off debts, replacing lost income, funding education expenses, or covering funeral costs.",
      ],
    },
    {
      id: 4,
      title: "How is the premium for term life insurance determined?",
      description: [
        "The premium for term life insurance is determined based on several factors, including:",
        "a) Age of the insured person: Younger individuals generally pay lower premiums.",
        "b) Health condition: The insured person's health and medical history play a role in determining the premium.",
        "c) Coverage amount: Higher coverage amounts result in higher premiums.",
        "d) Policy term: Longer terms may lead to higher premiums.",
        "Insurance providers assess these factors to calculate the risk associated with insuring the individual and determine the premium accordingly.",
      ],
    },
  ],
};
const TermLifeInsurance = () => {
  const { banner, compare, offers, works, faqData } =
    TermData as TermDataInterface;
  return (
    <>
      <Helmet>
        <title>
          Beemalaya | Compare and find best Term Life Insurance Policies in
          Nepal
        </title>
        <meta
          name="description"
          content="Find the best Term Life insurance Policy from Nepal's top insurer that suits you before you buy it.
          "
        />
      </Helmet>
      <Navbar />
      <TermLifeInsuranceForm />
      {/* <div className="Layout"> */}
      {/* <Banner {...banner} /> */}
      {/* <Definition {...compare} /> */}
      <Offers {...offers} />
      <Works {...works} />
      <FAQs data={faqData} />
      <Contact />
      <Footer />
      {/* </div> */}
    </>
  );
};

export default TermLifeInsurance;
