import React from "react";
import Navbar from "../../Components/Common/Navbar";
import Banner, { BannerDataInterface } from "../layouts/Banner";
import Definition, { CompareInterface } from "testPages/layouts/Definition";
import Offers, { OfferInterface } from "testPages/layouts/Offers";
import CostEffective from "../../assets/costEffective.svg";
import FinancialProtection from "../../assets/financialProtection.svg";
import Flexibility from "../../assets/flexibility.svg";
import Affordability from "../../assets/affordability.svg";
import LifeStage from "../../assets/lifeStage.svg";
import CoverageAmount from "../../assets/CoverageAmount.png";
import ProtectionDuration from "../../assets/ProtectionDuration.png";
import FinancialSecurity from "../../assets/FinancialSecurity.png";
import PeaceOfMind from "../../assets/PeaceOfMind.png";
import Works, { WorkInterface } from "testPages/layouts/Works";
import FAQs, { FAQItem } from "testPages/layouts/FAQs";
import Footer from "Components/landing/Footer";
import Contact from "Components/landing/Contact";
import WholeLifeInsuranceForm from "pages/Life/WholeLife";
import { Helmet } from "react-helmet-async";
interface WholeLifeInterface {
  banner: BannerDataInterface;
  compare: CompareInterface;
  offers: OfferInterface;
  works: WorkInterface;
  faqData: FAQItem[];
}
const WholeLife: WholeLifeInterface = {
  banner: {
    name: "Whole Life Insurance",
    link: "/whole-life-quote",
  },
  compare: {
    title: "How to compare Whole Life Insurance policy?",
    paragraph: [
      "1. Enter your age.",
      "2. Enter the term you want to be insured for.",
      "3. Enter the sum assured",
      "4. Compare between policies.",
      "5. View details.",
    ],
    name: "Whole Life Insurance",
    importance:
      "Whole Life Insurance provide financial protection and savings for the future needs of children.",
  },
  offers: {
    policyName: "Whole Life Insurance Policy",
    offerData: [
      {
        image: CostEffective,
        text: "Financial Protection",
      },
      {
        image: FinancialProtection,
        text: "Education Funding",
      },
      {
        image: Affordability,
        text: "Savings and Investment",
      },
      {
        image: Flexibility,
        text: "Long-Term Financial Planning",
      },
    ],
  },
  works: {
    policyName: "Whole Life Insurance Policy",
    insurance: "Whole Life Insurance",
    workData: [
      {
        id: "01",
        image: LifeStage,
        text: "Age 35, facing financial responsibilities like mortgage, children's education, etc.",
        heading: "Life Stage",
      },
      {
        id: "02",
        image: CoverageAmount,
        text: "NRS 500,000 policy ensures substantial support for beneficiaries.",
        heading: "Coverage Amount",
      },
      {
        id: "03",
        image: ProtectionDuration,
        text: "20-year term aligns with family's reliance on income.",
        heading: "Protection Duration",
      },
      {
        id: "04",
        image: FinancialSecurity,
        text: "Payout aids family's financial stability in case of death.",
        heading: "Financial Security",
      },
      {
        id: "05",
        image: PeaceOfMind,
        text: "Family's financial security brings peace, enabling joyful living.",
        heading: "Peace of Mind",
      },
    ],
  },
  faqData: [
    {
      id: 1,
      title: "What is whole life insurance?",
      description: [
        "Whole life insurance is a type of permanent life insurance that provides coverage for the entire lifetime of the insured person.",
        "Unlike term life insurance, which covers a specific term, whole life insurance offers lifelong protection as long as the premiums are paid. It also includes a cash value component that accumulates over time.",
      ],
    },
    {
      id: 2,
      title: "How does whole life insurance work?",
      description: [
        "Whole life insurance works by providing a death benefit to the beneficiaries upon the insured person's death.",
        "In addition to the death benefit, whole life insurance also accumulates cash value over time. Part of the premium payments goes towards the cost of insurance, and the remaining portion is invested by the insurance company, which helps grow the cash value component of the policy.",
      ],
    },
    {
      id: 3,
      title: "What are the benefits of whole life insurance?",
      description: [
        "Whole life insurance offers several benefits, including:",
        "a) Lifelong coverage: Whole life insurance provides coverage for the entire lifetime of the insured person, as long as premiums are paid.",
        "b) Cash value accumulation: The policy builds a cash value over time, which can be accessed by the policyholder through loans or withdrawals.",
        "c) Guaranteed death benefit: The death benefit is guaranteed and will be paid out to the beneficiaries upon the insured person's death, as long as premiums are paid.",
        "d) Tax advantages: The cash value growth within the policy is tax-deferred, and the death benefit is generally received by the beneficiaries tax-free.",
      ],
    },
    {
      id: 4,
      title: "How are the premiums for whole life insurance determined?",
      description: [
        "The premiums for whole life insurance are determined based on several factors, including:",
        "a) Age and health of the insured person: Younger and healthier individuals generally pay lower premiums.",
        "b) Coverage amount: Higher coverage amounts result in higher premiums.",
        "c) Cash value growth: The portion of the premium that goes towards the cash value accumulation affects the overall premium amount.",
        "Insurance providers assess these factors to calculate the risk associated with insuring the individual and determine the premium accordingly.",
      ],
    },
  ],
};
const WholeLifeInsurance = () => {
  const { banner, compare, offers, works, faqData } =
    WholeLife as WholeLifeInterface;

  return (
    <>
      <Helmet>
        <title>
          Beemalaya | Compare And Find Best Whole Life Insurance Policies in
          Nepal
        </title>
        <meta
          name="description"
          content="Find the best Whole Life insurance Policy from Nepal's top insurer that suits you before you buy it.
      "
        />
      </Helmet>
      <Navbar />
      {/* <div className="Layout"> */}
      {/* <Banner {...banner} /> */}
      {/* <Definition {...compare} /> */}
      <WholeLifeInsuranceForm />
      <Offers {...offers} />
      <Works {...works} />
      <FAQs data={faqData} />
      <Contact />
      <Footer />
      {/* </div> */}
    </>
  );
};

export default WholeLifeInsurance;
