import { createAsyncThunk } from "@reduxjs/toolkit";
import { getEndowmentLifePolicyApi } from "helpers/backend_helper";

export const getEndowmentLifePolicy = createAsyncThunk("policy/getEndowmentPolicy", async () => {
    try {
        const response =await getEndowmentLifePolicyApi();
    
        return response;
    } catch (error) {
        return error;
    }
});
