import Navbar from "Components/Common/Navbar";
// import Products from 'pages/Home/Products'
import React from "react";
import Products from "Components/landing/Products";

const Product = () => {
  return (
    <>
      <Navbar />
      {/* <div className="Layout"> */}
        <Products/>
      {/* </div> */}
    </>
  );
};

export default Product;
