import { createSlice } from "@reduxjs/toolkit";

import { getLifeInsuranceData } from "helpers/sessionStorageHelper";
import { getEndowmentLifePolicy } from "./thunk";
import { getPriceByAgeAndTermForLife } from "helpers/function_helper";

export interface EndowmentLifeInsurancePolicy {
  company: string;
  planName: "Endowment" | "Term Life" | "Whole Life";
  discountPercent?: number;
  bankPercent?: number;
  companyLogo: String;
  insuranceName: String;
  prices: {
    age: number;
    termPeriod: {
      totalYears: number;
      tablePercent: number;
      single?: number;
    }[];
  }[];
}
export interface EndowmentLifeInsuranceReducer {
  status: "success" | "pending" | "error";
  policies: EndowmentLifeInsurancePolicy[];
  age: number;
  sumAssured: number;
  term: number;
  policyModified: EndowmentLifeInsurancePolicy[][];
}

export const initialState: EndowmentLifeInsuranceReducer = {
  status: "pending",
  policies: [],
  age: 0,
  sumAssured: 0,
  term: 0,
  policyModified:[]
};


const endowmentSlice = createSlice({
  name: "endowmentPlan",
  initialState,
  reducers: {
    setEndowmentPolicyDetails: (state, action) => {
      state.age = action.payload.age;
      state.sumAssured = action.payload.sumAssured;
      state.term = action.payload.term;
    },
    setEndowmentPolicyInitial: (state) => {
      state.age = 0;
      state.sumAssured = 0;
      state.term = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEndowmentLifePolicy.fulfilled, (state, action) => {
      state.status = "success";
      state.policies = (
        action.payload as { data: EndowmentLifeInsurancePolicy[] }
      ).data;
      
      const policies = state.policies.filter((policy)=>{
        
        
        const {price} = getPriceByAgeAndTermForLife(policy, state.age, state.term);
        
        return price !== undefined;
      })
      
      // Step 1: Use reduce to group objects by the 'name' property
      const grouped = policies.reduce((acc: any, item: any) => {
        // If this group doesn't exist, initialize it as an empty array
        if (!acc[item.company]) {
          acc[item.company] = [];
        }
        // Add the current item to the correct group
        acc[item.company].push(item);

        return acc; // Return the accumulated result
      }, {}); // Initialize with an empty object

      // Step 2: Get the values of the grouped objects to create an array of arrays
      const result:any = Object.values(grouped);
      state.policyModified = result;
  
    });
  },
});

export const { setEndowmentPolicyDetails, setEndowmentPolicyInitial } =
  endowmentSlice.actions;

export default endowmentSlice.reducer;
