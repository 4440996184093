import React, { useState } from "react";
import AddIcon from "../../assets/AddIcon.svg";
import MinusIcon from "../../assets/MinusIcon.svg";
export interface FAQItem {
  id: Number;
  title: String;
  description: String[];
}
interface FAQSingleInterface {
  data: FAQItem;
  setSelected: any;
  selected: number;
}
interface FAQInterface {
  data: FAQItem[];
}
const FAQs: React.FC<FAQInterface> = ({ data }) => {
  const [selectedFAQ, setSelectedFAQ] = useState<number>(0);

  const FAQSingle: React.FC<FAQSingleInterface> = ({
    data,
    setSelected,
    selected,
  }) => {
    return (
      <div className="FAQ_data_item">
        <div
          className="FAQ_data_item_content"
          onClick={() => setSelected(data.id)}
        >
          <img
            src={selected === data.id ? MinusIcon : AddIcon}
            className="FAQ_data_item_icon"
            alt="ICON"
          />
          <p>{data.title} </p>
        </div>
        {selected === data.id && (
          <span>
            {data.description.map((dat) => (
              <p>{dat}</p>
            ))}
          </span>
        )}
      </div>
    );
  };
  return (
    <div className="FAQ">
      <h1 style={{ position: "relative", display: "inline-block" }}>
        Frequently Asked Questions
        <span />
      </h1>
      <div className="FAQ_data">
        {data.map((dat) => (
          <FAQSingle
            data={dat}
            setSelected={(item: number) => setSelectedFAQ(item)}
            selected={selectedFAQ}
          />
        ))}
      </div>
    </div>
  );
};

export default FAQs;
