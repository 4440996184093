import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { FormGroup, Label, Input } from "reactstrap";
interface ComprehensiveInterface {
  validation: any;
  setTab: any;
}
const Comprehensive: React.FC<ComprehensiveInterface> = ({
  validation,
  setTab,
}) => {
  const validation1: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      category: "",
      capacity: "",
      numberOfPassenger: "1",
      manufacturedYear: "",
      vechileCost: "",
      riotStrikeDamage: false,
    },

    validationSchema: Yup.object({
      category: Yup.string().required("Please Select a Category"),
      capacity: Yup.string().required("Please Select capacity"),
      numberOfPassenger: Yup.string().required("Please enter of passenger"),
      manufacturedYear: Yup.string().required("Enter Year of Manufacture"),
      vehicleCost: Yup.string().required("Enter Vehicle Cost"),
      riotStrikeDamage: Yup.boolean(),
    }),
    onSubmit: (values) => {
      setTab(3);
    },
  });
  return (
    <div className="Form">
      <div className="Form_Heading">
        <h1>Create a Two Wheeler Insurance Quote</h1>
      </div>
      <div className="Form_Body">
        <div className="Form_Body_Heading">
          <p>Four Wheeler Details</p>
          <div className="Form_Body_Heading_Container">
            <div
              className="Form_Body_Heading_Progress"
              style={{ width: "66%" }}
            ></div>
          </div>
          <span>Step 2 of 3</span>
        </div>
        <div className="Form_Body_Body">
          <div className="Form_Body_Body_InputBox">
            <p>Category *</p>
            <select
              className="Form_Body_Body_Input"
              onChange={(e) => {
                // validation.setFieldValue("gender", e.target.name);
                validation.setFieldValue("category", e.target.value);
                validation1.setFieldValue("category", e.target.value);
              }}
            >
              <option value="">Category</option>
              <option value="Electric Vehicle">Electric Vehicle</option>
              <option value="Motor Cycle">Motor Cycle</option>
              <option value="Scooter">Scooter</option>
            </select>
          </div>
          <div className="Form_Body_Body_InputBox">
            <p>Capacity *</p>
            <input
              className="Form_Body_Body_Input"
              type="text"
              placeholder="Cubic Capacity (CC) / Kilowatt"
              onChange={(e) => {
                validation.setFieldValue("capacity", e.target.value);
                validation1.setFieldValue("capacity", e.target.value);
              }}
            />
          </div>
          <div className="Form_Body_Body_InputBox">
            <p>Manufactured Year *</p>
            <select
              className="Form_Body_Body_Input"
              onChange={(e) => {
                validation.setFieldValue("manufacturedYear", e.target.value);
                validation1.setFieldValue("manufacturedYear", e.target.value);
              }}
            >
              <option value="">Manufactured Year</option>
              <option value="2024">2024</option>
              <option value="2023">2023</option>
              <option value="2022">2022</option>
              <option value="2021">2021</option>
              <option value="2020">2020</option>
              <option value="2019">2019</option>
              <option value="2018">2018</option>
              <option value="2017">2017</option>
              <option value="2016">2016</option>
              <option value="2015">2015</option>
              <option value="2014">2014</option>
              <option value="2013">2013</option>
              <option value="2013">2012</option>
              <option value="2011">2011</option>
              <option value="2010">2010</option>
              <option value="2009">2009</option>
              <option value="2008">2008</option>
              <option value="2007">2007</option>
              <option value="2006">2006</option>
              <option value="2005">2005</option>
              <option value="2004">2004</option>
              <option value="2003">2003</option>
              <option value="2002">2002</option>
              <option value="2001">2001</option>
              <option value="2000">2000</option>
              <option value="1999">1999</option>
              <option value="1998">1998</option>
              <option value="1997">1997</option>
              <option value="1996">1996</option>
              <option value="1995">1995</option>
              <option value="1994">1994</option>
              <option value="1993">1993</option>
              <option value="1992">1992</option>
              <option value="1991">1991</option>
              <option value="1990">1990</option>
              <option value="1989">1989</option>
              <option value="1988">1988</option>
              <option value="1987">1987</option>
              <option value="1986">1986</option>
              <option value="1985">1985</option>
            </select>
          </div>
          <div className="Form_Body_Body_InputBox">
            <p>Vechile Cost *</p>
            <input
              className="Form_Body_Body_Input"
              type="number"
              placeholder="Vechile Cost"
              onChange={(e) => {
                validation.setFieldValue("vehicleCost", e.target.value);
                validation1.setFieldValue("vehicleCost", e.target.value);
              }}
            />
          </div>
          <div className="Form_Body_Body_InputBox">
            <div className="Form_Body_Body_Flex">
              <input
                className="Form_Body_Body_Flex_Input"
                type="checkbox"
                checked={validation.values.riotStrikeDamage}
                onChange={() => {
                  validation.setFieldValue(
                    "riotStrikeDamage",
                    !validation.values.riotStrikeDamage
                  );
                  validation1.setFieldValue(
                    "riotStrikeDamage",
                    !validation.values.riotStrikeDamage
                  );
                }}
              />
              <p>For Riot and Strike Damage</p>
            </div>
          </div>
        </div>
        <div className="Form_Body_Button">
          <button
            type="submit"
            className="contactsubmit1"
            onClick={() => {
              setTab(1);
            }}
          >
            Back
          </button>
          <button
            type="submit"
            className="contactsubmit1"
            onClick={() => {
              validation1.handleSubmit();
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Comprehensive;
