import { createAsyncThunk } from "@reduxjs/toolkit";
import {

  getTermLifePolicyApi,
} from "helpers/backend_helper";

export const getTermLifePolicy = createAsyncThunk(
  "termLife/getTermLifePolicy",
  async () => {
    try {
      const response = await getTermLifePolicyApi();
      
      return response;
    } catch (error) {
      return error;
    }
  }
);
