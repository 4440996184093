// AUTHENTICATION
//REGISTER
export const POST_FAKE_REGISTER = "/auth/signup";

//LOGIN
export const POST_JWT_LOGIN = "/auth/login";
export const POST_LOGOUT = "/auth/logout-lms";
export const POST_JWT_REGISTER = "/auth/register";
export const POST_VERIFY_OTP = "/auth/verify-otp";
export const POST_RESEND_OTP = "/auth/resend-verify-otp";
export const POST_FAKE_PASSWORD_FORGET = "/auth/forgot-password";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

export const GET_TRAVEL_POLICY = "/policy/travel-policy";

export const GET_TWO_WHEELER_POLICY = "/policy/two-wheeler-policy";

export const GET_FOUR_WHEELER_POLICY = "/policy/four-wheeler-policy";
export const GET_LIFE_INSURANCE_POLICY = "/policy/life-insurance-policy";
export const GET_ENDOWMENT_INSURANCE_POLICY = "/endowment-life-policy";
export const TERM_LIFE_INSURANCE_POLICY = "/term-life-policy";
export const CHILDREN_LIFE_POLICY = "/children-life-policy";
export const WHOLE_LIFE_INSURANCE_POLICY = "/whole-life-insurance";
export const GET_BLOG = "/blogs";
