import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

interface ChoosePlanInterface {
  validation: any;
  setTab: any;
}
const ChoosePlan: React.FC<ChoosePlanInterface> = ({ validation, setTab }) => {
  const validation1: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      planType: "",
    },

    validationSchema: Yup.object({
      planType: Yup.string().required("Please Choose a Plan"),
    }),
    onSubmit: (values) => {
      setTab(2);
    },
  });
  return (
    <div className="Form">
      <div className="Form_Heading">
        <h1>Create a Two Wheeler Quote</h1>
      </div>
      <div className="Form_Body">
        <div className="Form_Body_Heading">
          <p>Choose your plan</p>
          <div className="Form_Body_Heading_Container">
            <div
              className="Form_Body_Heading_Progress"
              style={{ width: "33%" }}
            ></div>
          </div>
          <span>Step 1 of 3</span>
        </div>
        <div className="Form_Body_Body">
          <div className="Form_Body_Body_Hello">
            <button
              className={
                validation.values.planType === "Comprehensive"
                  ? "Form_Body_Body_ButtonPrimary"
                  : "Form_Body_Body_Button"
              }
              onClick={() => {
                validation1.setFieldValue("planType", "Comprehensive");
                validation.setFieldValue("planType", "Comprehensive");
              }}
            >
              <input
                type="radio"
                className="Form_Body_Body_Button_Radio"
                checked={validation.values.planType === "Comprehensive"}
              />
              <div className="Form_Body_Body_Button_Right">
                <h1>Comprehensive</h1>
                <p>Cover damages to sell as well as third party.</p>
              </div>
            </button>
            <button
              className={
                validation.values.planType === "ThirdParty"
                  ? "Form_Body_Body_ButtonPrimary"
                  : "Form_Body_Body_Button"
              }
              onClick={() => {
                validation1.setFieldValue("planType", "ThirdParty");
                validation.setFieldValue("planType", "ThirdParty");
              }}
            >
              <input
                type="radio"
                className="Form_Body_Body_Button_Radio"
                checked={validation.values.planType === "ThirdParty"}
              />
              <div className="Form_Body_Body_Button_Right">
                <h1>Third Party</h1>
                <p>Cover damages to only third party.</p>
              </div>
            </button>
          </div>
          {validation1.touched.planType && validation1.errors.planType && (
              <div className="text-danger">{validation1.errors.planType}</div>
            )}
        </div>
        <div className="Form_Body_Button">
          <div></div>
          <button
            type="submit"
            className="contactsubmit1"
            onClick={() => {
              
              validation1.handleSubmit();
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChoosePlan;
