import React from "react";
import GetAQuote1 from "assets/WhyChooseUs1.png";
import GetAQuote2 from "assets/WhyChooseUs2.png";
const GetAQuote = () => {
  return (
    <div className="GetAQuote">
      <div className="GetAQuote_Content">
        <img
          src={GetAQuote1}
          className="GetAQuote_Content_Image1"
          alt="Get A Quote Beemalaya Insurance"
        />
        <div className="GetAQuote_Content_Box">
            <h1>Looking for suitable policy? Get started right away!</h1>
            <button className="button-secondary">GET A QUOTE</button>
        </div>
        <img
          src={GetAQuote2}
          className="GetAQuote_Content_Image2"
          alt="Get A Quote Beemalaya Insurance"
        />
      </div>
    </div>
  );
};

export default GetAQuote;
