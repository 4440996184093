import Navbar from "Components/Common/Navbar";
import React, { useEffect, useState } from "react";
import Blog from "assets/blogs.svg";
import axios from "axios";
import { useParams } from "react-router-dom";
const Blogs = () => {
  const [blog, setBlog] = useState<any>(null);
  const { id } = useParams();
  useEffect(() => {
    console.log(id);
    axios.get(`https://api.beemalaya.com/blogs/${id}`).then((response: any) => {
      console.log("response is ", response);
      setBlog(response?.data);
    });
  }, []);
  return (
    <>
      <Navbar />
      <div className="Layout">
        <div className="Blogs">
          <div></div>
          {blog && (
            <h1
              className="Blogs_Heading"
              dangerouslySetInnerHTML={{ __html: blog.title }}
            />
          )}
          <div className="Blogs_Row">
            <div className="Blogs_Hello">
              {blog && (
                <>
                  <img
                    src={blog.image}
                    alt="Blog"
                    className="Blogs_Hello_Image"
                  />
                  <div
                    className="Blogs_Left"
                    dangerouslySetInnerHTML={{ __html: blog.description }}
                  />
                </>
              )}
            </div>
            <div className="Blogs_Right">
              <div className="Blogs_Right_Heading">
                <h3>Need Help?</h3>
                <p>We’re here for you! Get answers to all your questions.</p>
              </div>
              <div className="Blogs_Right_Box">
                <div className="Blogs_Right_InputBox">
                  <p>Full-Name</p>
                  <input
                    className="Blogs_Right_Input"
                    placeholder="Enter your full name"
                  />
                </div>
                <div className="Blogs_Right_InputBox">
                  <p>Phone Number</p>
                  <input
                    className="Blogs_Right_Input"
                    placeholder="Enter your phone number"
                  />
                </div>
                <div className="Blogs_Right_InputBox">
                  <p>Email</p>
                  <input
                    className="Blogs_Right_Input"
                    placeholder="Enter your email"
                  />
                </div>
              </div>
              <button className="button-primary-2 Blogs_Right_Button">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blogs;
