import React from "react";

interface WorkItem {
  id: String;
  image: any;
  text: String;
  heading: String;
}
interface WorkSingleInterface {
  key: number;
  data: WorkItem;
}
export interface WorkInterface {
  policyName: String;
  workData: WorkItem[];
  insurance: String;
}
const WorkSingle: React.FC<WorkSingleInterface> = ({ key, data }) => {
  return (
    <div className="WorkSingle">
      <div className="WorkSingle_Container">
        <img
          src={data.image}
          alt="Hello_Beemalaya"
          className="WorkSingle_Image"
        />
      </div>

      <div className="WorkSingle_Right">
        <h1>{data.heading}</h1>
        <p>{data.text}</p>
      </div>
      <div className="WorkSingle_Box">
        <p>{data.id}</p>
      </div>
    </div>
  );
};
const Works: React.FC<WorkInterface> = (data) => {
  return (
    <div className="Works">
      <h1>
        How does <span>{data.policyName}</span> Works?
      </h1>
      <p>
        Learn How Life Insurance Provides Financial Security with an Example
      </p>
      <div className="Works_Data">
        {data.workData.map((dat: WorkItem, index: number) => (
          <WorkSingle key={index} data={dat} />
        ))}
      </div>
    </div>
  );
};

export default Works;
