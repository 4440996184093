import React, { useState } from "react";
import Navbar from "Components/Common/Navbar";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import CoveragePlan from "./Forms/Coverage";
import ThirdParty from "./Forms/ThirdParty";
import PersonalDetails from "pages/Life/Forms/PersonalDetails";
import Comprehensive from "./Forms/Comprehensive";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import { postTravelQuote } from "slices/policy/thunk";

const TravelForm = () => {

  const [activeTab, setActiveTab] = useState(1);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      coverageType: "",
      planName: "",
      destinationType: "",
      startDate: "",
      endDate: "",
      name: "",
      phoneNumber: "",
      email: "",
    },

    validationSchema: Yup.object({
      coverageType: Yup.string().required("Please select coverage type"),
      planName: Yup.string().required("Please select plan name"),
      destinationType: Yup.string().required(
        "Please select place of destination"
      ),
      startDate: Yup.string().required("Please select start date"),
      endDate: Yup.string().required("Please select end date"),
      name: Yup.string().required("Please enter name of the traveller"),
      email: Yup.string().required("Please enter email of the traveller"),
      phoneNumber: Yup.string().required("Phone number is required"),
    }),
    onSubmit: (values) => {
      dispatch(postTravelQuote(values));
      localStorage.setItem("travelData", JSON.stringify(values));
      navigate("/travel-policy");
    },
  });


  return (
    <React.Fragment>
      {/* <Navbar /> */}
      <div className="Layout">
        {activeTab === 1 && (
          <CoveragePlan
            validation={validation}
            setTab={(item: number) => setActiveTab(item)}
          />
        )}
        {activeTab === 2 && (
          <PersonalDetails
            validation={validation}
            setTab={(item: number) => setActiveTab(item)}
            steps={2}
            type="Create a travel insurance plan"
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default TravelForm;
