import {
  getPriceByAgeAndCC,
  getPriceByAgeAndTermForLife,
  getPriceByCC,
} from "helpers/function_helper";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "slices";
import { EndowmentLifeInsuranceReducer } from "slices/endowmentPolicy/reducer";
import { TwoWheelerInsuranceReducer } from "slices/twoWheeler/reducer";

const TwoWheelerPolicyCard: React.FC<any> = ({ policy }) => {
  const [tablePercent, setTablePercent] = useState<number>(0);
  const [netPremium, setNetPremium] = useState<number>(0);
  const TwoWheelerPolicyState: TwoWheelerInsuranceReducer = useSelector(
    (state: RootState) => state.TwoWheeler
  );
  const {
    cc,
    planType,
    noOfPassenger,
    manufacturedYear,
    vechileCost,
    riotStrikeDamage,
  } = TwoWheelerPolicyState;
  useEffect(() => {
    if (policy) {
      if (planType === "ThirdParty") {
        const { price } = getPriceByCC(policy, cc);
        const totalCost = price.thirdPartyAdd + (policy.vatPercent * price?.thirdPartyAdd /100) + policy.stampCost;
        setNetPremium(totalCost || 0);
      } else {
        const currentDate = new Date();
        const age = currentDate.getFullYear() - manufacturedYear;
        // const age = new Date().getFullYear - manu
        const { price } = getPriceByAgeAndCC(policy, age, cc);

        const percentPrice = price?.bikeAgeBands.filter((band: any) => {
          return age >= band.minAge && age <= band.maxAge;
        });
        const totalCost =
          Math.round(
            (parseFloat(percentPrice[0].costPercent) * vechileCost) / 100
          ) +
          Math.round(
            (parseFloat(percentPrice[0].costPercent) *
              parseFloat(percentPrice[0].percent) *
              vechileCost) /
              10000
          ) +
          noOfPassenger * 700;
        const totalNetCost = totalCost + policy.vatPercent * totalCost + policy.stampCost;
        if (riotStrikeDamage) {
          setNetPremium(totalNetCost + (0.1 / 100) * vechileCost);
        } else {
          setNetPremium(totalNetCost);
        }
      }
    }
  }, [policy]);
  return (
    <div className="cardcontainer_card_insurance">
      <div className="cardcontainer_card_insurance_company">
        <img className="companylogo" src={policy.companyLogo} alt="" />
        <div className="companyname">{policy.company}</div>
      </div>
      <div className="cardcontainer_card_insurance_policycontent">
        <div className="policyinfo">
          <div>
            <div className="policyinfo_title"> Policy name:</div>
            <div className="policyinfo_titleresult">
              Two Wheeler Insurance Plan
            </div>
          </div>
          <div>
            <span className="policyinfo_title">Premium amount</span>
            <span className="policyinfo_titleresult">Rs {netPremium}</span>
          </div>
        </div>

        {/* <div className="premiumamount">
          <div className="button-primary-2">View details</div>
          <div className="button-primary-2">Select Plan</div>
        </div> */}
      </div>
    </div>
  );
};

export default TwoWheelerPolicyCard;
