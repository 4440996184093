import { createSlice } from "@reduxjs/toolkit";

import { getTravelPolicy } from "./thunk";
interface AgeBand {
  minAge: number;
  maxAge: number;
  price: number;
}
interface Price {
  minDays: number;
  maxDays: number;
  ageBands: AgeBand[];
}

export interface TravelInsurancePlan {
  company: string;
  companyLogo: string;
  planName: "Medical only - A to C" | "Package policy - A to N";
  destinationType:
    | "Worldwide including USA & Canada"
    | "Worldwide excluding USA & Canada"
    | "Asian countries"
    | "SAARC Countries";
  stampCost?: string;
  prices: Price[];
}

export interface TravelInsuranceReducer {
  status: "success" | "pending" | "error";
  policies: TravelInsurancePlan[];
}

export const initialState: TravelInsuranceReducer = {
  status: "pending",
  policies: [],
};

const travelSlice = createSlice({
  name: "travelPlan",
  initialState,
  reducers: {
    setTravelPolicyDetails: (state, action) => {
      //   state.cc = action.payload.cc;
      //   state.planType = action.payload.planType;
      //   state.noOfPassenger = action.payload.numberOfPassenger;
      //   state.manufacturedYear = action.payload.manufacturedYear;
      //   state.vechileCost = action.payload.vehicleCost;
      //   state.riotStrikeDamage = action.payload.riotStrikeDamage;
    },
    setTravelPolicyInitial: (state) => {
      //   state.cc = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTravelPolicy.fulfilled, (state, action) => {
      state.status = "success";
      state.policies = (action.payload as { data: TravelInsurancePlan[] }).data;
    });
  },
});

export const { setTravelPolicyDetails, setTravelPolicyInitial } =
  travelSlice.actions;

export default travelSlice.reducer;
