import { createSlice } from "@reduxjs/toolkit";
import {
  getFourWheelerPolicy,
  getLifeInsurancePolicy,
  getTravelPolicy,

} from "./thunk";
import { getLifeInsuranceData } from "helpers/sessionStorageHelper";

interface AgeBand {
  minAge: number;
  maxAge: number;
  price: number;
}

interface Price {
  minDays: number;
  maxDays: number;
  ageBands: AgeBand[];
}

export interface TravelInsurancePlan {
  company: string;
  companyLogo: string;
  planName: "Medical only - A to C" | "Package policy - A to N";
  destinationType:
    | "Worldwide including USA & Canada"
    | "Worldwide excluding USA & Canada"
    | "Asian countries"
    | "SAARC Countries";
  stampCost?: string;
  prices: Price[];
}

export interface TwoWheelerInsurance {
  company: string;
  planName: string;
  stampCost: number;
  vatPercent: number;
  prices: {
    minCC: number;
    maxCC: number;
    thirdPartyAdd?: number;
    bikeAgeBands: {
      minAge: number;
      maxAge: number;
      percent: number;
      costPercent: number;
    }[];
  }[];
  leastAmount: number;
}
export interface FourWheelerInsurancePlan {
  company: string;
  planName: "Comprehensive" | "Third-party";
  stampCost: number;
  vatPercent: number;
  perSeatCost: number;
  companyLogo: string;
  prices: {
    minCC: number;
    maxCC: number;
    thirdPartyAdd?: number;
    costPercentBelowTwentyLakhs: number;
    costPercentAboveTwentyLakhs: number;
    reduceAmount: number;
    vechileAgeBands: {
      minAge: number;
      maxAge: number;
      percentAdd: number;
    }[];
  }[];
}

export interface TravelPolicyInterface {
  travelPolicy: any;
  travelPolicies: TravelInsurancePlan[];
  fourWheelerPolicies: FourWheelerInsurancePlan[];
  lifeInsurancePolicies: LifeInsurancePlan[];
  fetchData: boolean;
  twoWheelerPolicies: TwoWheelerInsurance[];
  cubicCapacity: number;
  bikeManufacturedYear: any;
  bikeCost: string;
  vehicleManufacturedYear: any;
  vehicleCost: number;
  termOfInsurance: number;
  dateOfBirthOfInsured: any;
  sumAssured: number;
}
export interface LifeInsurancePlan {
  company: string;
  planName: "Endowment" | "Term Life" | "Whole Life";
  discountPercent?: number;
  bankPercent?: number;
  prices: {
    age: number;
    termPeriod: {
      totalYears: number;
      tablePercent: number;
    }[];
  }[];
}

export const initialState: TravelPolicyInterface = {
  travelPolicy: {},
  travelPolicies: [],
  fourWheelerPolicies: [],
  fetchData: true,
  lifeInsurancePolicies: [],
  twoWheelerPolicies: [],
  cubicCapacity: 0,
  bikeManufacturedYear: [],
  bikeCost: "0",
  vehicleManufacturedYear: [],
  vehicleCost: 0,
  termOfInsurance: 0,
  dateOfBirthOfInsured: '',
  sumAssured: 0,
};

const policySlice = createSlice({
  name: "policy",
  initialState,
  reducers: {
    addTravelPolicy: (state, action) => {
      state.travelPolicy = action.payload;
    },
    changeFetchData: (state, action) => {
      state.fetchData = action.payload;
    },
    setBikeDetails: (state, action) => {
      state.cubicCapacity = action.payload.cubicCapacity;
      state.bikeManufacturedYear = action.payload.bikeManufacturedYear;
      state.bikeCost = action.payload.bikeCost;
    },
    updateBikeProperties: (state, action) => {
      state.cubicCapacity = action.payload.cubicCapacity;
      state.bikeManufacturedYear = action.payload.bikeManufacturedYear;
      state.bikeCost = action.payload.bikeCost;
    },
    setVehicleDetails: (state, action) => {
      state.cubicCapacity = action.payload.cubicCapacity;
      state.vehicleManufacturedYear = action.payload.vehicleManufacturedYear;
      state.vehicleCost = action.payload.vehicleCost;
    },
    updateVehicleProperties: (state, action) => {
      state.cubicCapacity = action.payload.cubicCapacity;
      state.vehicleManufacturedYear = action.payload.vehicleManufacturedYear;
      state.vehicleCost = action.payload.vehicleCost;
    },
    setLifeInsuranceDetails: (state, action) => {
      state.termOfInsurance = action.payload.termOfInsurance;
      state.dateOfBirthOfInsured = action.payload.dateOfBirth;
      state.sumAssured = action.payload.sumAssured;
    },
    setLifeInsuranceToEmpty: (state) => {
      state.lifeInsurancePolicies = [];
    },
    setFourWheelerEmpty: (state) => {
      state.fourWheelerPolicies = [];
    },
    setTwoWheelerEmpty: (state) => {
      state.twoWheelerPolicies = [];
    },
    setTravelPolicyEmpty: (state) => {
      state.travelPolicies = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTravelPolicy.fulfilled, (state, action) => {
      state.fetchData = false;
      state.travelPolicies = (action.payload as { data: any }).data;
    });
   
    builder.addCase(getFourWheelerPolicy.fulfilled, (state, action) => {
      state.fetchData = false;
      state.fourWheelerPolicies = (action.payload as { data: any }).data;
    });

    builder.addCase(getLifeInsurancePolicy.fulfilled, (state, action) => {
      
      
      const lifeInsuranceData = getLifeInsuranceData()
      state.fetchData = false;
      state.lifeInsurancePolicies = (action.payload as { data: any }).data;

      state.sumAssured= lifeInsuranceData.sumAssured
    });
  },
});

export const {
  addTravelPolicy,
  changeFetchData,
  setBikeDetails,
  updateBikeProperties,
  setVehicleDetails,
  updateVehicleProperties,
  setLifeInsuranceDetails,
  setLifeInsuranceToEmpty,
  setFourWheelerEmpty,
  setTwoWheelerEmpty,
  setTravelPolicyEmpty
} = policySlice.actions;

export default policySlice.reducer;
