import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import EndowmentPlan from "pages/Life/EndowmentPlan";
import TravelPolicy from "pages/Policy/TravelPolicy/TravelPolicy";
import TwoWheelerPolicy from "pages/Policy/TwoWheelerPolicy/TwoWheelerPolicy";
import FourWheelerPolicy from "pages/Policy/FourWheelerPolicy/FourWheelerPolicy";
import EndowmentLifeInsurance from "testPages/Endowment";
import EndowmentPolicy from "pages/Policy/EndowmentPolicy/EndowmentPolicy";
import TwoWheeler from "testPages/TwoWheeler";
import FourWheeler from "testPages/FourWheeler";
import TwoWheelerForm from "pages/TwoWheeler";
import FourWheelerForm from "pages/FourWheeler.tsx";
import TravelForm from "pages/Travel";
import Travel from "testPages/Travel";
import TermLifeInsurance from "testPages/Term";
import TermLifeInsuranceForm from "pages/Life/TermLifeInsurance";
import TermLifePolicy from "pages/Policy/TermLifePolicy/TermLifePolicy";
import ChildrenLifeInsurance from "testPages/Children";
import ChildrenLifeInsuranceForm from "pages/Life/ChildrenPlan";
import ChildrenLifePolicy from "pages/Policy/ChildrenPolicy/ChildrenPolicy";
import WholeLifeInsuranceForm from "pages/Life/WholeLife";
import WholeLifePolicy from "pages/Policy/WholeLifePolicy/WholeLifePolicy";
import LandingContainer from "pages/Landing";
import WholeLifeInsurance from "testPages/WholeLife";
import Product from "pages/Products";

import Blog from "pages/Blog";

import Rendering from "../Components/Common/planSidebar";
import Blogs from "pages/Blogs/blogs";
import About from "pages/About";
import Landing from "Components/landing/landing";

const authProtectedRoutes = [
  {
    path: "/",
    exact: true,
    component: <Navigate to="/" />,
  },
  { path: "*", component: <Navigate to="/" /> },
];
const publicRoutes = [
  { path: "/about", component: <About /> },
  { path: "/blogs/:id", component: <Blogs /> },
  { path: "/products", component: <Product /> },
  { path: "/", component: <LandingContainer /> },
  { path: "/endowment", component: <EndowmentLifeInsurance /> },
  { path: "/term", component: <TermLifeInsurance /> },
  { path: "/two-wheeler", component: <TwoWheeler /> },
  { path: "/four-wheeler", component: <FourWheeler /> },
  { path: "/travel", component: <Travel /> },
  { path: "/children", component: <ChildrenLifeInsurance /> },
  { path: "/whole-life", component: <WholeLifeInsurance /> },

  { path: "/endowment-life-insurance-policy", component: <EndowmentPolicy /> },

  { path: "/two-wheeler-policy", component: <TwoWheelerPolicy /> },

  { path: "/four-wheeler-policy", component: <FourWheelerPolicy /> },

  { path: "/travel-policy", component: <TravelPolicy /> },
  { path: "/term-life-insurance-policy", component: <TermLifePolicy /> },

  {
    path: "/children-life-insurance-policy",
    component: <ChildrenLifePolicy />,
  },

  {
    path: "/whole-life-policy",
    component: <WholeLifePolicy />,
  },
  {

    path: "/blog",
    component: <Blog />,
  },
  {path:"*", component:<LandingContainer/>}
];

export { authProtectedRoutes, publicRoutes };
