import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
const Navbar = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [nonDropdownOpen, setNonDropdownOpen] = useState(false);
  const toggleDropDown = () => {
    setNonDropdownOpen(false);
    setDropdownOpen((prevState) => !prevState);
  };
  const nonToggleDropDown = () => {
    setDropdownOpen(false);
    setNonDropdownOpen((prevState) => !prevState);
  };

  const handleClickOutside = (ref: any, handler: any) => {
    const listener = (event: any) => {
      // Check if click is outside the dropdown container (ref.current)
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      // Call the handler function (toggleDropDown or nonToggleDropDown)
      handler();
    };

    // Add event listener to the document
    document.addEventListener("click", listener);

    // Cleanup function to remove event listener on component unmount
    return () => {
      document.removeEventListener("click", listener);
    };
  };

  // Usage example:
  const dropdownRef = useRef(null);
  const dropdownRef1 = useRef(null);
  useEffect(() => {
    // Cleanup function for handleClickOutside effect
    const cleanup = handleClickOutside(dropdownRef1, () => {
      setNonDropdownOpen(false);
    });
    return cleanup;
  }, [dropdownRef1]);
  useEffect(() => {
    // Cleanup function for handleClickOutside effect
    const cleanup = handleClickOutside(dropdownRef, () => {
      setDropdownOpen(false);
    });
    return cleanup;
  }, [dropdownRef]); // Re-run effect if dropdownRef changes

  // Function to update the window width
  const handleResize = () => {
    if (window.innerWidth > 700) {
      setIsOpen(false);
    }

    setWindowWidth(window.innerWidth);
  };

  useLayoutEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  console.log(isOpen);
  return (
    <div className="Navbar">
      <div className="Navbar_Content">
        <div className="Navbar_Logo" onClick={() => navigate("/")}>
          <img
            src="https://utfs.io/f/384f1969-9b39-4393-83e0-a7592e4d24ee-rkvw1g.svg"
            alt="Beemalaya Logo"
            className="Navbar_Logo_Image"
          />
        </div>
        {windowWidth < 700 && (
          <div className="Navbar_Hamburger">
            <img
              src="https://utfs.io/f/c297f18b-e2e8-4b6f-89ae-dd08c105ee0e-3kjk09.svg"
              alt="Hamburger"
              onClick={toggleMenu}
              className="Navbar_Hamburger_Image"
            />
          </div>
        )}
        <div className={isOpen ? "Navbar_Hundred" : "Navbar_Auto"}>
          <div className="Navbar_Hundred_Hello"></div>
          <div className={`Navbar_Right ${isOpen ? "open" : ""}`}>
            {isOpen && (
              <div className="Navbar_Right_Box">
                <img
                  src="https://utfs.io/f/384f1969-9b39-4393-83e0-a7592e4d24ee-rkvw1g.svg"
                  alt="Beemalaya Logo"
                  className="Navbar_Right_Box_Logo"
                />
                <img
                  src=" https://utfs.io/f/bd94b0c4-a79b-4960-ac82-15752e71405c-6u0i4t.svg"
                  alt="Beemalaya Cancel"
                  onClick={toggleMenu}
                />
              </div>
            )}
            <Link to="/" className="Navbar_Right_Link">
              Home
            </Link>
            <Link to="" className="Navbar_Right_Link">
              <p onClick={toggleDropDown} className="dropbtn" ref={dropdownRef}>
                Life {"  "}
                <img
                  src="https://utfs.io/f/6bcf45b2-39b3-45d4-b322-d40e06aa0c50-g5zyuj.svg"
                  alt="Arrow down"
                />
              </p>

              {dropdownOpen && (
                <div className="Navbar_Right_Link_Dropdown">
                  <Link to="/">Endowment Plans</Link>
                  <Link to="/">Term Plan</Link>
                  <Link to="/">Whole Life Plan</Link>
                  <Link to="/">Children Plan</Link>
                </div>
              )}
            </Link>
            <Link to="" className="Navbar_Right_Link">
              <p
                onClick={nonToggleDropDown}
                className="dropbtn"
                ref={dropdownRef1}
              >
                Non Life {"  "}
                <img
                  src="https://utfs.io/f/6bcf45b2-39b3-45d4-b322-d40e06aa0c50-g5zyuj.svg"
                  alt="Arrow down"
                />
              </p>

              {nonDropdownOpen && (
                <div className="Navbar_Right_Link_Dropdown">
                  <Link to="/">Two Wheeler Insurance</Link>
                  <Link to="/">Four Wheeler Insurance</Link>
                  <Link to="/">Travel Insurance</Link>
                </div>
              )}
            </Link>
            <Link to="/about" className="Navbar_Right_Link">
              About us
            </Link>
            <Link to="/blog" className="Navbar_Right_Link">
              Blogs
            </Link>
            {isOpen === false && (
              <button className="button-primary">Contact us</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
