import { getPriceByAgeAndTermForLife } from "helpers/function_helper";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "slices";
import { WholeLifeInsuranceReducer } from "slices/wholeLifePlan/reducer";

const WholeLifePolicyCard: React.FC<any> = ({
  policy,
  setSidebarVisible,
  setSidebardata,
}) => {
  const [tablePercent, setTablePercent] = useState<number>(0);
  const [netPremium, setNetPremium] = useState<number>(0);
  const PolicyState: WholeLifeInsuranceReducer = useSelector(
    (state: RootState) => state.WholeLife
  );
  const { age, sumAssured, term } = PolicyState;
  const viewdetailshandler = (e: any) => {
    const minage = policy.prices[0].age;
    const maxage = policy.prices[policy.prices.length - 1].age;
    const data = {
      company: policy.company,
      insuranceName: policy.insuranceName,
      description: policy.description,
      companyLogo: policy.miniimage ? policy.miniimage : policy.companyLogo,
      minage,
      maxage,
    };
    setSidebardata(data);
    setSidebarVisible(true);
    const layoutSection: any = document.getElementById("layoutplan");
    layoutSection?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    if (policy) {
      const { price } = getPriceByAgeAndTermForLife(policy, age, term);

      const number = price?.termPeriod.filter(
        (p: any) =>
          p.totalYears === (typeof term === "string" ? parseInt(term) : term)
      );

      if (number) {
        setTablePercent(number[0]?.tablePercent);
        setNetPremium(
          ((number[0]?.tablePercent - policy.discountPercent) * sumAssured) /
            1000
        );
      }
    }
  }, [policy]);
  return (
    netPremium > 0 && (
      <div className="cardcontainer_card">
        <div className="cardcontainer_card_insurance">
          <div className="cardcontainer_card_insurance_company">
            <img className="companylogo" src={policy.companyLogo} alt="" />
            <div className="companyname">{policy.company}</div>
          </div>
          <div className="cardcontainer_card_insurance_policycontent">
            <div className="policyinfo">
              <div>
                <div className="policyinfo_title"> Policy name:</div>
                <div className="policyinfo_titleresult">
                  {policy.insuranceName}
                </div>
              </div>
              <div>
                <span className="policyinfo_title">Premium amount</span>
                <span className="policyinfo_titleresult">
                  Rs {Math.round(netPremium)}
                </span>
              </div>
              <button className="button-primary-2" onClick={viewdetailshandler}>
                View details
              </button>
            </div>

            {/* <div className="premiumamount">
            <div className="button-primary-2">View details</div>
            <div className="button-primary-2">Select Plan</div>
          </div> */}
          </div>
        </div>
      </div>
    )
  );
};

export default WholeLifePolicyCard;
