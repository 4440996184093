import Navbar from "Components/Common/Navbar";
import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import { RootState } from "slices";
import { useSelector } from "react-redux";

import { calculateAgeFromDate } from "helpers/function_helper";

import {
  setTermPolicyDetails,
  TermLifeInsuranceReducer,
} from "slices/termLife/reducer";
import { getTermLifePolicy } from "slices/termLife/thunk";
import Keyboard from "assets/keyboard.svg";
import PlanSidebar from "Components/Common/planSidebar";
import {
  ChildrenLifeReducer,
  setChildrenPlanDetails,
} from "slices/childrenPlan/reducer";
import { getChildrenPolicy } from "slices/childrenPlan/thunk";
import { getWholeLifePolicy } from "slices/wholeLifePlan/thunk";
import {
  setWholeLifePolicyDetails,
  WholeLifeInsuranceReducer,
} from "slices/wholeLifePlan/reducer";
import WholeLifePolicyCard from "./WholeLifePolicyCard";
// import EndowmentPolicyCard from "../EndowmentPolicy/EndowmentPolicyCard";
// import SumAssured from "pages/Life/Forms/SumAssured";

const WholeLifePolicy = () => {
  document.title =  "Beemalaya | Whole Life Policy";
  const [show, setShow] = useState<number[]>([]);
  const [sidebarvisible, setSidebarVisible] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const [sidebardata, setSidebardata] = useState<any>({});
  useEffect(() => {
    const data: any = localStorage.getItem("wholeLife");
    const wholeLife = JSON.parse(data);
    const age = parseInt(wholeLife.dateOfBirth);
    dispatch(
      setWholeLifePolicyDetails({
        sumAssured: wholeLife.sumAssured,
        term: parseInt(wholeLife.term),
        age: age,
      })
    );
    dispatch(getWholeLifePolicy());
  }, [dispatch]);
  const PolicyState: WholeLifeInsuranceReducer = useSelector(
    (state: RootState) => state.WholeLife
  );
  const { policies, policyModified, status } = PolicyState;

  return (
    <>
      <Navbar />
      <div
        className="Layout"
        style={{
          filter: sidebarvisible ? "blur(2px)" : "none",
        }}
      >
        <div className="plan">
          <div className="plan_content">
            <div className="plan_content_searchresult">
              {" "}
              Result: {policyModified?.length} plans match your search
            </div>
            <div className="cardcontainer">
              {status === "success" &&
                policyModified.map((policy, index: number) => {
                  return (
                    <>
                      <WholeLifePolicyCard
                        policy={policy[0]}
                        setSidebarVisible={setSidebarVisible}
                        sidebardata={sidebardata}
                        setSidebardata={setSidebardata}
                      />{" "}
                      {show.includes(index) &&
                        policy.map(
                          (dat, index) =>
                            index !== 0 && (
                              <WholeLifePolicyCard
                                policy={dat}
                                setSidebarVisible={setSidebarVisible}
                                sidebardata={sidebardata}
                                setSidebardata={setSidebardata}
                              />
                            )
                        )}
                      {policy.length > 1 && show.includes(index) ? (
                        <div
                          className="cardcontainer_card_moreplan"
                          onClick={() => {
                            let shown = [...show];
                            const subIndex = shown.indexOf(index);
                            if (subIndex > -1) {
                              shown.splice(subIndex, 1);
                            }
                            setShow(shown);
                            // show = true;
                          }}
                        >
                          view less plan
                          <img src={Keyboard} alt="Key board" />
                        </div>
                      ) : (
                        <>
                          {policy.length > 1 && (
                            <div
                              className="cardcontainer_card_moreplan"
                              onClick={() => {
                                let shown = [...show];
                                shown.push(index);
                                setShow(shown);
                                // show = true;
                              }}
                            >
                              view more plan
                              <img src={Keyboard} alt="Key board" />
                            </div>
                          )}
                        </>
                      )}
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      {sidebarvisible && (
        <PlanSidebar
          setSidebarVisible={setSidebarVisible}
          sidebardata={sidebardata}
        />
      )}
    </>
  );
};

export default WholeLifePolicy;
